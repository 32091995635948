import React, { useState, useCallback, memo, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import debounce from 'lodash/debounce';

interface SearchInputProps {
  onSearch: (query: string) => void;
}

const SearchInput = memo(({ onSearch }: SearchInputProps) => {
  const [localQuery, setLocalQuery] = useState('');

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      console.log('Debounced search with:', value);
      onSearch(value);
    }, 300),
    [onSearch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalQuery(value);
    
    try {
      debouncedSearch(value);
    } catch (error) {
      console.error('Error in search debounce:', error);
    }
  };

  const clearSearch = () => {
    setLocalQuery('');
    debouncedSearch.cancel();
    onSearch('');
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      onSearch(localQuery);
    }} className="relative">
      <input
        type="text"
        value={localQuery}
        onChange={handleChange}
        placeholder="Search across all meetings..."
        className="w-full px-4 py-2 rounded-lg border border-input bg-background"
      />
      {localQuery && (
        <button
          type="button"
          onClick={clearSearch}
          className="absolute right-10 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
        >
          <X className="h-4 w-4" />
        </button>
      )}
      <button
        type="submit"
        className="absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
      >
        <Search className="h-4 w-4" />
      </button>
    </form>
  );
});

SearchInput.displayName = 'SearchInput';

export default SearchInput; 