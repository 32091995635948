import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { Thread, Meeting } from '../types';
import UserProfile from './UserProfile';
import SpeakersList from './SpeakersList';

interface UserInfo {
  userId: string;
  userName: string;
  imageUrl: string;
  token: string;
}

interface SidebarProps {
  threads: Thread[];
  selectedThread: string | null;
  meetings: Meeting[];
  isViewingMeetings: boolean;
  onToggleView: () => void;
  onSelectMeeting: (id: string) => void;
  onLogout: () => void;
  userInfo: UserInfo;
  fetchThreads: () => Promise<void>;
  onSpeakerMeetingsSelect: (speaker: string, meetings: Meeting[]) => void;
  onSelectThread?: (threadId: string) => void;
  onNewChat?: () => void;
  onDeleteThread?: (threadId: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ 
  threads, 
  selectedThread, 
  meetings, 
  isViewingMeetings,
  onToggleView,
  onSelectMeeting, 
  onLogout, 
  userInfo, 
  fetchThreads, 
  onSpeakerMeetingsSelect, 
  onSelectThread, 
  onNewChat, 
  onDeleteThread 
}) => {
  const [filteredMeetings, setFilteredMeetings] = useState(meetings);
  const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>([]);
  const isNewChatOpen = selectedThread === null;

  useEffect(() => {
    setFilteredMeetings(meetings);
  }, [meetings]);

  const handleSpeakersChange = (selectedSpeakers: string[]) => {
    setSelectedSpeakers(selectedSpeakers);
    if (selectedSpeakers.length === 0) {
      setFilteredMeetings(meetings);
    } else {
      const filtered = meetings.filter(meeting =>
        meeting.speakers.some(speaker => selectedSpeakers.includes(speaker))
      );
      setFilteredMeetings(filtered);
    }
  };

  return (
    <div className="w-[280px] bg-gray-800 text-white p-4 flex flex-col h-screen [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:bg-black [&::-webkit-scrollbar-track]:bg-black [&::-webkit-scrollbar]:bg-black">
      <div className="mb-4 flex flex-col items-center">
        <img src="/assets/logodark.svg" alt="Logo" className="w-11 h-11 mb-1" />
      </div>

      <div className="flex-1 overflow-hidden hover:overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:bg-black [&::-webkit-scrollbar-track]:bg-black [&::-webkit-scrollbar]:bg-black">
        <SpeakersList
          meetings={meetings}
          onSpeakersChange={handleSpeakersChange}
          token={userInfo.token}
          onSpeakerMeetingsSelect={onSpeakerMeetingsSelect}
        />
      </div>

      <div className="h-px bg-gray-700 mb-2"></div>
      <a
        href="https://dashboard.vexa.ai"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center hover:bg-black/20 text-white font-medium py-1.5 px-6 rounded-full mb-2 transition-colors duration-200"
      >
        Legacy Dashboard
      </a>
      <UserProfile
        userInfo={userInfo}
        onLogout={onLogout}
      />
    </div>
  );
};

export default Sidebar;
