import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import { TranscriptEntry } from '../types/meetings';
import { Search, X, ChevronLeft, ChevronRight, Copy, Check, FileText, Loader2 } from 'lucide-react';

interface TranscriptPanelProps {
  meetingId: string | null;
  token: string;
  onClose: () => void;
}

interface MeetingDetails {
  meeting_id: string;
  timestamp: string;
  meeting_name: string;
  transcript: TranscriptEntry[];
  meeting_summary: string;
  speakers: string[];
  access_level: string;
  is_owner: boolean;
  is_indexed: boolean;
}

const formatTimestamp = (timestamp: string) => {
  return new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
};

const formatTranscriptForExport = (transcript: TranscriptEntry[]) => {
  return transcript
    .map(entry => `[${formatTimestamp(entry.timestamp)}] ${entry.speaker}:\n${entry.content}`)
    .join('\n\n');
};

const highlightText = (text: string, query: string, entryIndex: number): string => {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  let matchIndex = 0;
  return text.replace(regex, (match) => {
    const highlightIndex = matchIndex++;
    return `<mark class="bg-yellow-100 dark:bg-yellow-900/30" data-entry="${entryIndex}" data-highlight="${highlightIndex}">${match}</mark>`;
  });
};

const TranscriptPanel: React.FC<TranscriptPanelProps> = ({ meetingId, token, onClose }) => {
  const [meetingDetails, setMeetingDetails] = useState<MeetingDetails | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState<number>(0);
  const [totalHighlights, setTotalHighlights] = useState<number>(0);
  const [isCopyingTranscript, setIsCopyingTranscript] = useState(false);
  const [originalTranscript, setOriginalTranscript] = useState<TranscriptEntry[]>([]);
  const [showRawContent, setShowRawContent] = useState<Record<number, boolean>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  React.useEffect(() => {
    if (meetingId) {
      fetchMeetingDetails(meetingId);
    }
  }, [meetingId]);

  const fetchMeetingDetails = async () => {
    if (!meetingId) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      const apiUrl = `${API_BASE_URL}/meeting/${meetingId}/details`;
      console.log('Fetching meeting details from:', apiUrl);
      
      const response = await fetch(apiUrl, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        console.error('API Error:', {
          status: response.status,
          statusText: response.statusText
        });
        throw new Error(`API Error: ${response.status}`);
      }
      
      const data = await response.json();
      
      const parsedData = {
        ...data,
        transcript: typeof data.transcript === 'string' ? JSON.parse(data.transcript) : data.transcript
      };
      
      setMeetingDetails(parsedData);
      setOriginalTranscript(parsedData.transcript);
    } catch (err) {
      console.error('Error fetching meeting details:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch meeting details');
    } finally {
      setIsLoading(false);
    }
  };

  const formatTranscriptForCopy = (transcript: TranscriptEntry[]) => {
    return transcript
      .map(entry => `${entry.speaker}:\n${entry.content.replace(/<\/?b>/g, '')}`)
      .join('\n\n');
  };

  const handleCopyTranscript = async () => {
    if (!meetingDetails?.transcript) return;
    
    setIsCopyingTranscript(true);
    try {
      const formattedTranscript = formatTranscriptForCopy(meetingDetails.transcript);
      await navigator.clipboard.writeText(formattedTranscript);
      setTimeout(() => setIsCopyingTranscript(false), 2000);
    } catch (error) {
      console.error('Failed to copy transcript:', error);
      setIsCopyingTranscript(false);
    }
  };

  const handleExportTranscript = async () => {
    if (!meetingDetails?.transcript) return;
    
    const formattedTranscript = formatTranscriptForExport(meetingDetails.transcript);
    const blob = new Blob([formattedTranscript], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = `transcript-${meetingDetails.meeting_id}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setCurrentHighlightIndex(0);
    
    if (!searchQuery.trim()) {
      setTotalHighlights(0);
      setMeetingDetails(prev => prev ? { ...prev, transcript: originalTranscript } : null);
      return;
    }

    if (meetingDetails?.transcript) {
      let totalMatches = 0;
      const filteredTranscript = originalTranscript.map((entry, entryIndex) => {
        const regex = new RegExp(searchQuery, 'gi');
        const matches = (entry.content.match(regex) || []).length;
        totalMatches += matches;
        
        return {
          ...entry,
          html_content: highlightText(entry.html_content || entry.content, searchQuery, entryIndex),
          content: highlightText(entry.content, searchQuery, entryIndex)
        };
      });

      setTotalHighlights(totalMatches);
      setMeetingDetails({
        ...meetingDetails,
        transcript: filteredTranscript
      });
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
    if (meetingDetails) {
      setMeetingDetails({
        ...meetingDetails,
        transcript: originalTranscript
      });
    }
    setTotalHighlights(0);
    setCurrentHighlightIndex(0);
  };

  const scrollToHighlight = (index: number) => {
    const mark = document.querySelector(`mark[data-highlight="${index}"]`);
    if (mark) {
      // Remove previous active highlight
      document.querySelectorAll('mark.active').forEach(m => 
        m.classList.remove('active', 'ring-2', 'ring-yellow-500'));
      
      // Add active highlight
      mark.classList.add('active', 'ring-2', 'ring-yellow-500');
      
      // Find the containing transcript card
      const card = mark.closest('.transcript-card');
      
      // Scroll the card into view
      card?.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'nearest'
      });
    }
  };

  const navigateHighlight = (direction: 'next' | 'prev') => {
    if (totalHighlights === 0) return;
    
    let newIndex = direction === 'next' 
      ? (currentHighlightIndex + 1) % totalHighlights
      : (currentHighlightIndex - 1 + totalHighlights) % totalHighlights;
      
    setCurrentHighlightIndex(newIndex);
    scrollToHighlight(newIndex);
  };

  if (isLoading) {
    return (
      <div className="w-[400px] flex flex-col h-full bg-muted/30 border-l border-r border-border">
        <div className="flex-1 flex items-center justify-center">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Loader2 className="h-4 w-4 animate-spin" />
            Loading transcript...
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-[400px] flex flex-col h-full bg-muted/30 border-l border-r border-border">
        <div className="flex-1 flex items-center justify-center">
          <div className="text-destructive text-sm">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-[400px] flex flex-col h-full bg-background border-l border-r border-border/40">
      <div className="flex-none bg-muted/[0.08] backdrop-blur-sm border-b border-border/40 p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium">Transcript</h2>
          <div className="flex items-center gap-4">
            <button
              onClick={handleExportTranscript}
              className="flex items-center gap-1.5 text-sm text-muted-foreground hover:text-foreground"
            >
              <FileText className="h-4 w-4" />
              Export
            </button>
            <button
              onClick={handleCopyTranscript}
              className="flex items-center gap-1.5 text-sm text-muted-foreground hover:text-foreground"
            >
              <Copy className="h-4 w-4" />
              Copy
            </button>
            <button
              onClick={onClose}
              className="text-muted-foreground hover:text-foreground"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSearch} className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              handleSearch(e as any);
            }}
            placeholder="Search transcript..."
            className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
          />
          {searchQuery && (
            <>
              <div className="absolute right-24 top-1/2 -translate-y-1/2 flex items-center gap-1 text-xs text-muted-foreground">
                {totalHighlights > 0 && (
                  <>
                    <button
                      onClick={() => navigateHighlight('prev')}
                      className="p-1 hover:text-foreground"
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </button>
                    <span>{currentHighlightIndex + 1}/{totalHighlights}</span>
                    <button
                      onClick={() => navigateHighlight('next')}
                      className="p-1 hover:text-foreground"
                    >
                      <ChevronRight className="h-4 w-4" />
                    </button>
                  </>
                )}
              </div>
              <button
                type="button"
                onClick={clearSearch}
                className="absolute right-10 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
              >
                <X className="h-4 w-4" />
              </button>
            </>
          )}
          <button
            type="submit"
            className="absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
          >
            <Search className="h-4 w-4" />
          </button>
        </form>
      </div>

      <div className="flex-1 overflow-y-auto">
        <div className="p-4">
          {meetingDetails?.transcript.length === 0 ? (
            <div className="text-muted-foreground">No results found</div>
          ) : (
            meetingDetails?.transcript.map((entry: TranscriptEntry, index: number) => (
              <div key={index} className="mb-2 last:mb-0 group rounded-lg border border-border/30 
                bg-white dark:bg-card/95 p-3 hover:border-border/50 
                transition-colors transcript-card">
                <div className="flex justify-between items-center">
                  <div className="font-semibold text-primary text-sm tracking-tight">
                    {entry.speaker}
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => {
                        const newState = { ...showRawContent };
                        newState[index] = !newState[index];
                        setShowRawContent(newState);
                      }}
                      className="text-xs text-muted-foreground hover:text-foreground opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      {showRawContent[index] ? 'see cleaned' : 'see original'}
                    </button>
                    <div className="text-xs text-muted-foreground/90">
                      {formatTimestamp(entry.timestamp)}
                    </div>
                  </div>
                </div>
                <div 
                  className="mt-0.5 text-sm leading-relaxed text-foreground/90"
                  dangerouslySetInnerHTML={{ 
                    __html: showRawContent[index] ? entry.content : (entry.html_content || entry.content)
                  }}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default TranscriptPanel;