import { API_BASE_URL } from '../config';

interface GoogleAuthResponse {
  user_id: string;
  user_name: string;
  image: string | null;
  token: string;
}

interface UtmParams {
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_term?: string | null;
  utm_content?: string | null;
}

export class GoogleAuth {
  private static CLIENT_ID = '733104961366-fre6q88nt37qk26nfnemrpquvh8in4k7.apps.googleusercontent.com';
  private static REDIRECT_URI = import.meta.env.VITE_REDIRECT_URI;
  private static CLIENT_SECRET = import.meta.env.VITE_GOOGLE_CLIENT_SECRET;
  private static SCOPES = [
    'profile',
    'email',
    'openid'
  ];

  static initiateLogin(utmParams?: UtmParams) {
    console.log('🚀 Initiating Google login...');
    const params = new URLSearchParams({
      client_id: this.CLIENT_ID,
      redirect_uri: this.REDIRECT_URI,
      response_type: 'code',
      scope: this.SCOPES.join(' '),
      access_type: 'offline',
      prompt: 'consent',
      ...utmParams
    });

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
  }

  static async handleCallback(code: string, utmParams?: UtmParams): Promise<GoogleAuthResponse | null> {
    try {
      console.log('🔄 Exchanging code for tokens...');
      const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          code,
          client_id: this.CLIENT_ID,
          client_secret: this.CLIENT_SECRET,
          redirect_uri: this.REDIRECT_URI,
          grant_type: 'authorization_code',
        })
      });

      if (!tokenResponse.ok) {
        console.error('❌ Token exchange failed:', tokenResponse.status);
        throw new Error(`Token exchange failed: ${tokenResponse.status}`);
      }

      const tokenData = await tokenResponse.json();
      console.log('✅ Received Google tokens');

      if (!tokenData.id_token) {
        console.error('❌ No id_token in response');
        throw new Error('No id_token in response');
      }

      console.log('🔄 Sending token to backend...');
      const authResponse = await fetch(`${API_BASE_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          token: tokenData.id_token,
          ...utmParams
        })
      });

      if (!authResponse.ok) {
        console.error('❌ Backend auth failed:', authResponse.status);
        throw new Error(`Backend auth failed: ${authResponse.status}`);
      }

      const data: GoogleAuthResponse = await authResponse.json();
      console.log('✅ Received backend response:', data);
      return data;
    } catch (error) {
      console.error('❌ Google auth error:', error);
      return null;
    }
  }
} 