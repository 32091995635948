import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Message, SearchResult, Thread, MeetingModeState } from '../types';
import { Send, Loader2, Copy, Check } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReferenceCircle from './ReferenceCircle';
import { debounce } from 'lodash';
import ChatContextBar from './ChatContextBar';
import { useMeetingSelection } from '../hooks/useMeetingSelection';
import MarkdownRenderer from './MarkdownRenderer';

interface FilterChatPanelProps {
  messages: Message[];
  isLoading: boolean;
  inputMessage: string;
  setInputMessage: (message: string) => void;
  handleSubmit: (e: React.FormEvent) => void;
  searchResults: SearchResult[];
  threads: Thread[];
  selectedThreadId: string | null;
  onThreadSelect: (threadId: string) => void;
  startNewConversation: () => void;
  speaker?: string | string[];
  additionalBody?: {
    meeting_ids?: string[];
    speaker?: string | string[];
  };
  selectedMeeting?: {
    meeting_id: string;
    meeting_name: string;
    timestamp: string;
    speakers: string[];
    meeting_summary?: string;
  };
  meetingMode: MeetingModeState;
  meetings?: Array<{
    meeting_id: string;
    meeting_name: string;
    timestamp: string;
    speakers: string[];
    discussion_points?: Array<{ topic_name: string }>;
  }>;
  clearFilters?: () => void;
  onMeetingSelect?: (meetingId: string | null) => void;
}

const FilterChatPanel: React.FC<FilterChatPanelProps> = ({
  messages,
  isLoading,
  inputMessage,
  setInputMessage,
  handleSubmit,
  searchResults,
  threads,
  selectedThreadId,
  onThreadSelect,
  startNewConversation,
  speaker,
  additionalBody,
  selectedMeeting,
  meetingMode,
  meetings = [],
  clearFilters,
  onMeetingSelect,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const [localInput, setLocalInput] = useState(inputMessage);
  const heightUpdateRef = useRef<number>();
  const measurementCache = useRef<{height: number, lineHeight: number}>();
  const messageToSubmitRef = useRef<string>('');

  const adjustTextareaHeight = useCallback(() => {
    if (!textareaRef.current) return;
    
    if (heightUpdateRef.current) {
      cancelAnimationFrame(heightUpdateRef.current);
    }

    heightUpdateRef.current = requestAnimationFrame(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      if (!measurementCache.current) {
        const style = window.getComputedStyle(textarea);
        const lineHeight = parseInt(style.lineHeight);
        measurementCache.current = {
          lineHeight,
          height: textarea.scrollHeight
        };
      }

      const { lineHeight } = measurementCache.current;
      
      textarea.style.height = 'auto';
      const newHeight = textarea.scrollHeight;
      
      const initialLines = messages.length > 0 ? 1 : 3;
      const minHeight = lineHeight * initialLines;
      const maxHeight = lineHeight * 10;
      
      textarea.style.height = `${Math.min(Math.max(newHeight, minHeight), maxHeight)}px`;
      textarea.style.overflowY = newHeight > maxHeight ? 'auto' : 'hidden';
      measurementCache.current.height = newHeight;
    });
  }, [messages.length]);

  const debouncedSetInput = useCallback(
    debounce((value: string) => {
      setInputMessage(value);
    }, 300),
    []
  );

  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const messageToSend = localInput.trim();
      if (!messageToSend) return;
      
      // Store message in ref for persistence
      messageToSubmitRef.current = messageToSend;
      
      // Create a synthetic form event
      const formEvent = {
        preventDefault: () => {},
        target: e.target,
      } as React.FormEvent;
      
      try {
        // Submit first
        await handleSubmit(formEvent);
        
        // Only clear after successful submission
        setLocalInput('');
        setInputMessage('');
      } catch (error) {
        console.error('Failed to submit message:', error);
        // Optionally restore input if submission failed
        messageToSubmitRef.current = '';
      }
    }
  };

  const handleCopy = (content: string, index: number) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 2000);
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setLocalInput(value);
    debouncedSetInput(value);
    adjustTextareaHeight();
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const messageToSend = localInput.trim();
    if (!messageToSend) return;
    
    // Store message in ref for persistence
    messageToSubmitRef.current = messageToSend;
    
    try {
      // Submit first
      await handleSubmit(e);
      
      // Only clear after successful submission
      setLocalInput('');
      setInputMessage('');
    } catch (error) {
      console.error('Failed to submit message:', error);
      // Optionally restore input if submission failed
      messageToSubmitRef.current = '';
    }
  };

  const renderMessage = (message: Message, index: number) => {
    return (
      <div className={`mb-4 ${message.role === 'user' ? 'flex justify-end' : 'flex justify-start'}`}>
        <div className={`inline-block px-3 py-2 rounded-2xl ${
          message.role === 'user' 
            ? 'bg-gray-100 text-black'
            : 'text-secondary-foreground'
        } max-w-[80%] relative group`}>
          {message.role === 'assistant' && (
            <button
              onClick={() => handleCopy(message.content, index)}
              className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
            >
              {copiedIndex === index ? (
                <Check size={16} className="text-green-500" />
              ) : (
                <Copy size={16} className="text-gray-500 hover:text-gray-700" />
              )}
            </button>
          )}
          {message.role === 'assistant' ? (
            <div className="flex items-start">
              <img src="/assets/logolight.svg" alt="Assistant" className="w-6 h-6 mr-4 mt-1 flex-shrink-0" />
              <div className="flex-grow">
                <MarkdownRenderer 
                  content={message.content} 
                  className="text-secondary-foreground" 
                />
              </div>
            </div>
          ) : (
            <div className="whitespace-pre-wrap">{message.content}</div>
          )}
        </div>
      </div>
    );
  };

  const handleNewConversation = () => {
    startNewConversation();
    setLocalInput('');
  };

  const formatSpeakerDisplay = useCallback(() => {
    // For other cases, show 'Chat' if there are messages
    if (messages.length > 0) return '';

    // Default case
    return '';
  }, [messages.length]);

  useEffect(() => {
    return () => {
      if (heightUpdateRef.current) {
        cancelAnimationFrame(heightUpdateRef.current);
      }
      debouncedSetInput.cancel();
    };
  }, []);

  useEffect(() => {
    // Restart chat when meeting changes or threads are loaded/reloaded
    if ((selectedMeeting || threads.length > 0) && messages.length > 0) {
      handleNewConversation();
    }
  }, [selectedMeeting?.meeting_id, threads.length, speaker]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [messages.length, adjustTextareaHeight]);

  const renderThreadsList = () => {
    if (!threads?.length) return null;

    return (
      <div className="mb-4">
        <div className="text-sm font-medium text-muted-foreground mb-2">Recent Conversations:</div>
        <div className="grid grid-cols-2 gap-2">
          {threads.slice(0, 10).map((thread) => (
            <button
              key={thread.thread_id}
              onClick={() => onThreadSelect(thread.thread_id)}
              className={`text-left px-2.5 py-1.5 rounded-md text-sm truncate
                border border-border/40 hover:border-border
                ${selectedThreadId === thread.thread_id 
                  ? 'bg-primary/5 text-primary border-primary/30' 
                  : 'hover:bg-muted/30 text-foreground'}`}
            >
              {thread.thread_name}
            </button>
          ))}
        </div>
      </div>
    );
  };

  // Update isGlobalSearch condition to consider both speaker and selectedMeeting
  const isGlobalSearch = (!speaker || (Array.isArray(speaker) && speaker.length === 0)) 
    && !selectedMeeting;

  // Add debug logs
  console.log('Debug Global Search:', {
    speaker,
    selectedMeeting,
    isGlobalSearch,
    isArray: Array.isArray(speaker),
    speakerLength: Array.isArray(speaker) ? speaker.length : 'not array'
  });

  const handleGlobalSearch = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('Global Search clicked - Event:', e);
    
    try {
      // Log state before changes
      console.log('Before clear - State:', {
        speaker,
        selectedMeeting,
        hasMessages: messages.length > 0
      });

      // Clear everything in sequence
      if (clearFilters) {
        console.log('Calling clearFilters');
        clearFilters();
      }

      // Clear meeting selection
      if (onMeetingSelect) {
        console.log('Clearing meeting selection');
        onMeetingSelect(null);
      }

      // Clear thread selection
      if (onThreadSelect) {
        console.log('Clearing thread selection');
        onThreadSelect('');
      }

      // Start new conversation and clear input
      console.log('Starting new conversation');
      startNewConversation();
      setLocalInput('');
      setInputMessage('');

      // Log state after changes
      console.log('After clear - State:', {
        speaker,
        selectedMeeting,
        hasMessages: messages.length > 0
      });
    } catch (error) {
      console.error('Error in handleGlobalSearch:', error);
    }
  };

  const getContextTitle = () => {
    if (isGlobalSearch) {
      return "Search Across All Your Meetings";
    }
    
    if (selectedMeeting) {
      return `About "${selectedMeeting.meeting_name}"`;
    }
    
    if (speaker) {
      const speakers = Array.isArray(speaker) ? speaker : [speaker];
      if (speakers.length === 1) {
        return `Chat with ${speakers[0]}`;
      }
      if (speakers.length === 2) {
        return `Chat with ${speakers[0]} and ${speakers[1]}`;
      }
      return `Chat with ${speakers.length} speakers`;
    }
    
    return "";
  };

  const getContextDescription = () => {
    if (isGlobalSearch) {
      return "Ask any question about your meetings - I'll search through everything to find what you need";
    }
    
    if (selectedMeeting) {
      return "Ask specific questions about this meeting's discussions, decisions, and action items";
    }
    
    if (speaker) {
      const speakers = Array.isArray(speaker) ? speaker : [speaker];
      if (speakers.length === 1) {
        return `Ask questions about meetings involving ${speakers[0]}`;
      }
      return `Ask questions about meetings involving these speakers`;
    }
    
    return "What would you like to know?";
  };

  return (
    <div className="flex flex-col h-full bg-background">
      {/* Context Header */}
      <div className="p-4 border-b border-border/40 bg-background/50">
        <div className="max-w-4xl mx-auto flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <h2 className="text-2xl font-semibold text-foreground">
                {formatSpeakerDisplay()}
              </h2>
              {searchResults.length > 0 && (
                <span className="text-sm px-2 py-0.5 rounded-full bg-primary/10 text-primary">
                  {searchResults.length} result{searchResults.length === 1 ? '' : 's'}
                </span>
              )}
            </div>
            <div className="flex gap-2">
              {!isGlobalSearch && (
                <button
                  onClick={handleGlobalSearch}
                  className="px-3 py-1.5 text-sm font-medium rounded-md
                           bg-background border border-border hover:bg-muted
                           transition-colors"
                >
                  ← Back to Global Search
                </button>
              )}
              {messages.length > 0 && (
                <button
                  onClick={startNewConversation}
                  className="px-3 py-1.5 text-sm rounded-md 
                            bg-primary text-primary-foreground 
                            hover:bg-primary/90 transition-colors"
                >
                  New Conversation
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="flex-1 overflow-y-auto bg-background">
        <div className="max-w-4xl mx-auto px-6">
          {messages.length === 0 ? (
            <div className="py-12 space-y-8">
              <div className="text-center space-y-3">
                <h3 className="text-2xl font-medium text-foreground">
                  {getContextTitle()}
                </h3>
                <p className="text-base text-muted-foreground max-w-xl mx-auto">
                  {getContextDescription()}
                </p>
              </div>
              
              {isGlobalSearch ? (
                threads?.length > 0 && (
                  <div className="max-w-2xl mx-auto space-y-4">
                    <h4 className="text-sm font-medium text-muted-foreground">
                      Recent Global Conversations
                    </h4>
                    <div className="grid grid-cols-1 gap-2">
                      {threads.slice(0, 5).map((thread) => (
                        <button
                          key={thread.thread_id}
                          onClick={() => onThreadSelect(thread.thread_id)}
                          className="text-left px-4 py-2.5 rounded-lg
                                   bg-muted/30 hover:bg-muted/50 
                                   transition-all duration-200
                                   flex items-center justify-between group
                                   border border-border/30 hover:border-border"
                        >
                          <span className="text-sm font-medium text-foreground/80">
                            {thread.thread_name}
                          </span>
                          <span className="text-xs font-medium text-primary/80 opacity-0 
                                         group-hover:opacity-100 transition-opacity">
                            Continue →
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                )
              ) : (
                threads?.length > 0 && (
                  <div className="max-w-2xl mx-auto space-y-4">
                    <h4 className="text-sm font-medium text-muted-foreground">
                      Recent Meeting Conversations
                    </h4>
                    <div className="grid grid-cols-2 gap-2">
                      {threads.slice(0, 10).map((thread) => (
                        <button
                          key={thread.thread_id}
                          onClick={() => onThreadSelect(thread.thread_id)}
                          className={`text-left px-2.5 py-1.5 rounded-md text-sm truncate
                            border border-border/40 hover:border-border
                            ${selectedThreadId === thread.thread_id 
                              ? 'bg-primary/5 text-primary border-primary/30' 
                              : 'hover:bg-muted/30 text-foreground'}`}
                        >
                          {thread.thread_name}
                        </button>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="py-6">
              {messages.map((message, index) => renderMessage(message, index))}
              {isLoading && (
                <div className="flex items-center justify-center py-8 text-muted-foreground">
                  <Loader2 className="w-5 h-5 animate-spin mr-2" />
                  <span>Searching through meetings...</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Input Area */}
      <div className="border-t border-border">
        <div className="max-w-4xl mx-auto p-6">
          <form onSubmit={onSubmit} className="relative group">
            <textarea
              ref={textareaRef}
              value={localInput}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={isGlobalSearch 
                ? "Ask anything about your meetings..."
                : `Ask about "${selectedMeeting?.meeting_name}"`}
              className="w-full px-5 py-4 rounded-xl
                       bg-muted/50 border border-border/50 
                       resize-none transition-colors
                       focus:outline-none focus:ring-1 focus:ring-primary/20
                       placeholder:text-muted-foreground/50
                       text-base"
              disabled={isLoading}
              rows={1}
            />
            <div className="absolute right-3 bottom-3 flex items-center gap-2">
              <span className="text-xs text-muted-foreground">
                Press Enter ↵
              </span>
              <button
                type="submit"
                disabled={isLoading || !localInput.trim()}
                className="p-2.5 rounded-lg
                         bg-primary/90 text-primary-foreground
                         hover:bg-primary transition-colors
                         disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Send className="h-4 w-4" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterChatPanel;