import { useState, useEffect } from 'react';

interface UseMeetingSelectionProps {
  meetings: any[];
  selectedMeetingId: string | null;
  speaker?: string | string[];
}

export const useMeetingSelection = ({ meetings, selectedMeetingId, speaker }: UseMeetingSelectionProps) => {
  const [config, setConfig] = useState({
    mode: 'multiple' as const,
    selectedIds: [] as string[],
    api: {
      endpoint: '/chat/meeting/summary',
      body: { meeting_ids: [] as string[], speaker: undefined as string | string[] | undefined }
    }
  });

  useEffect(() => {
    console.group('🔄 useMeetingSelection Update');
    console.log('Speaker:', speaker);
    console.log('Selected Meeting:', selectedMeetingId);
    console.log('Meetings count:', meetings.length);

    setConfig(current => {
      const newConfig = selectedMeetingId ? {
        mode: 'single' as const,
        selectedIds: [selectedMeetingId],
        api: {
          endpoint: '/chat/meeting',
          body: { meeting_ids: [selectedMeetingId] }
        }
      } : {
        mode: 'multiple' as const,
        selectedIds: meetings.map(m => m.meeting_id),
        api: {
          endpoint: '/chat/meeting/summary',
          body: {
            meeting_ids: meetings.map(m => m.meeting_id),
            speaker
          }
        }
      };

      console.log('New config:', newConfig);
      console.groupEnd();
      return newConfig;
    });
  }, [meetings, selectedMeetingId, speaker]); // Added speaker as dependency

  return config;
}; 