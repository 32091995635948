import React, { useState } from 'react';
import { LogOut } from 'lucide-react';
import { UserInfo } from '../types';

interface UserProfileProps {
  userInfo: UserInfo;
  onLogout: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({
  userInfo: { userName, imageUrl },
  onLogout
}) => {
  const [imageError, setImageError] = useState(false);
  
  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      onLogout();
    }
  };

  const getInitial = (name: string) => {
    const firstWord = name.split(' ')[0];
    return firstWord.charAt(0).toLocaleUpperCase();
  };

  const renderAvatar = () => {
    if (imageUrl && !imageError) {
      return (
        <img
          src={imageUrl}
          alt={userName}
          className="w-9 h-9 rounded-full object-cover bg-gray-600"
          referrerPolicy="no-referrer"
          crossOrigin="anonymous"
          onError={() => setImageError(true)}
        />
      );
    }

    return (
      <div className="w-9 h-9 rounded-full bg-gray-600 flex items-center justify-center">
        <span className="text-base font-medium text-white select-none">
          {getInitial(userName)}
        </span>
      </div>
    );
  };

  return (
    <div className="mt-2 p-3 border-t border-gray-700">
      <div className="flex items-center gap-3 p-2 rounded-lg hover:bg-gray-700/50 transition-colors">
        <div className="flex-shrink-0">
          {renderAvatar()}
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-white truncate">{userName}</p>
        </div>
        <button
          onClick={handleLogout}
          className="p-2 hover:bg-gray-600 rounded-lg transition-colors"
          title="Logout"
        >
          <LogOut size={16} className="text-gray-400 hover:text-white" />
        </button>
      </div>
    </div>
  );
};

export default UserProfile; 