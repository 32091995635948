import { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';

interface FeatureItem {
  title: string;
  description: string;
  videoSegment?: {
    startSecond: number;
    endSecond: number;
  };
}

interface FeatureSectionProps {
  title: string;
  subtitle: string;
  items: FeatureItem[];
  video: {
    id: string;
    isPortrait?: boolean;
    defaultSegment?: {
      startSecond: number;
      endSecond: number;
    };
  };
  videoOnRight?: boolean;
}

const FeatureSection = ({ title, subtitle, items, video, videoOnRight = true }: FeatureSectionProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeSegment = items[activeIndex]?.videoSegment || video.defaultSegment;

  useEffect(() => {
    if (!activeSegment) return;

    const segmentDuration = activeSegment.endSecond - activeSegment.startSecond;
    const switchTimeout = (segmentDuration + 1) * 1000;

    const timer = setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, switchTimeout);

    return () => clearTimeout(timer);
  }, [activeIndex, activeSegment, items.length]);

  const VideoComponent = useMemo(() => (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      className={`relative w-full mx-auto ${
        video.isPortrait ? 'max-w-[400px]' : 'max-w-[1000px]'
      }`}
    >
      <div className="bg-zinc-900/50 backdrop-blur-sm rounded-3xl p-4 border border-white/5">
        <div className={`relative bg-black/60 rounded-2xl overflow-hidden ${
          video.isPortrait 
            ? 'aspect-[9/16] max-h-[600px]' 
            : 'aspect-[16/9] max-h-[600px]'
        } shadow-lg`}>
          <div className="absolute inset-0 pointer-events-none z-10" />
          <iframe
            key={`${video.id}-${activeIndex}`}
            className="w-full h-full pointer-events-none"
            src={`https://www.youtube.com/embed/${video.id}?autoplay=1&mute=1&loop=1&playlist=${video.id}&playsinline=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3${
              activeSegment ? `&start=${activeSegment.startSecond}&end=${activeSegment.endSecond}` : ''
            }`}
            title="Feature Demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
          />
        </div>
      </div>
    </motion.div>
  ), [video.id, video.isPortrait, activeSegment]);

  const Features = () => (
    <div className="space-y-8">
      <div className="space-y-4 text-center">
        <h2 className="text-3xl font-bold">{title}</h2>
        <p className="text-xl text-gray-400">{subtitle}</p>
      </div>
      <div className={`${video.isPortrait ? 'space-y-6' : 'grid grid-cols-3 gap-6'}`}>
        {items.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className={`cursor-pointer p-4 rounded-xl transition-colors ${
              activeIndex === index 
                ? 'bg-zinc-800 ring-1 ring-white/10'
                : 'hover:bg-zinc-900'
            }`}
            onClick={() => setActiveIndex(index)}
          >
            <h3 className={`text-lg font-semibold mb-2 ${!video.isPortrait && 'text-center'}`}>
              {item.title}
            </h3>
            <p className={`text-gray-400 ${!video.isPortrait && 'text-center'}`}>
              {item.description}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );

  return (
    <section className="py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className={`${
          video.isPortrait 
            ? `grid md:grid-cols-2 gap-8 items-center ${videoOnRight ? '' : 'md:grid-flow-dense'}`
            : 'flex flex-col gap-12'
        }`}>
          {videoOnRight ? (
            <>
              <div className={video.isPortrait ? '' : 'max-w-3xl mx-auto w-full'}>
                <Features />
              </div>
              <div className={video.isPortrait ? '' : 'w-full'}>
                {VideoComponent}
              </div>
            </>
          ) : (
            <>
              <div className={video.isPortrait ? '' : 'max-w-3xl mx-auto w-full'}>
                <Features />
              </div>
              <div className={video.isPortrait ? '' : 'w-full'}>
                {VideoComponent}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;