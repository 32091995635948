import { motion } from 'framer-motion';
import { ArrowRight, Linkedin, ChevronLeft, ChevronRight } from 'lucide-react';
import Header from './Header';
import ValuePropositions from './ValuePropositions';
import Testimonials from './Testimonials';
import CookieConsent from '../common/CookieConsent'
import { useState, useEffect } from 'react';
import FeatureSection from './FeatureSection';

const LandingPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    {
      src: "./assets/main_layout.png",
      alt: "Vexa Dashboard Interface"
    },
    {
      src: "./assets/meeting_page.png",
      alt: "Vexa Meeting Page"
    }
  ];

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div className="relative min-h-screen bg-black text-white">
      {/* Background lines - moved to top of component, before any content */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none" style={{ zIndex: 0 }}>
        <svg className="absolute w-screen h-screen opacity-30" viewBox="0 0 100 100" preserveAspectRatio="none">
          {/* Define gradients */}
          <defs>
            <linearGradient id="fadeFromLeft" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: 'white', stopOpacity: 1 }} />
              <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 1 }} />
            </linearGradient>
            <linearGradient id="fadeFromTop" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: 'white', stopOpacity: 1 }} />
              <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 1 }} />
            </linearGradient>
            <linearGradient id="fadeDiagonal1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: 'white', stopOpacity: 1 }} />
              <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 1 }} />
            </linearGradient>
            <linearGradient id="fadeDiagonal2" x1="100%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: 'white', stopOpacity: 1 }} />
              <stop offset="50%" style={{ stopColor: 'white', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 1 }} />
            </linearGradient>
          </defs>

          {/* Main diagonal lines */}
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, ease: "easeInOut" }}
            d="M-10 -10 L 110 110"
            stroke="url(#fadeDiagonal1)"
            strokeWidth="0.1"
            fill="none"
          />
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, delay: 0.2, ease: "easeInOut" }}
            d="M-10 110 L 110 -10"
            stroke="url(#fadeDiagonal2)"
            strokeWidth="0.1"
            fill="none"
          />
          
          {/* Vertical lines */}
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, delay: 0.4, ease: "easeInOut" }}
            d="M25 -10 L 25 110"
            stroke="url(#fadeFromTop)"
            strokeWidth="0.05"
            fill="none"
          />
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, delay: 0.6, ease: "easeInOut" }}
            d="M75 -10 L 75 110"
            stroke="url(#fadeFromTop)"
            strokeWidth="0.05"
            fill="none"
          />
          
          {/* Horizontal lines */}
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, delay: 0.8, ease: "easeInOut" }}
            d="M-10 25 L 110 25"
            stroke="url(#fadeFromLeft)"
            strokeWidth="0.05"
            fill="none"
          />
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
            d="M-10 75 L 110 75"
            stroke="url(#fadeFromLeft)"
            strokeWidth="0.05"
            fill="none"
          />
          
          {/* Additional diagonal lines */}
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, delay: 1.2, ease: "easeInOut" }}
            d="M-10 25 L 110 75"
            stroke="url(#fadeDiagonal1)"
            strokeWidth="0.075"
            fill="none"
          />
          <motion.path
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 2, delay: 1.4, ease: "easeInOut" }}
            d="M-10 75 L 110 25"
            stroke="url(#fadeDiagonal2)"
            strokeWidth="0.075"
            fill="none"
          />
        </svg>
      </div>

      {/* Main content wrapper - ensure it's above the background */}
      <div className="relative z-10">
        <Header />
        <section className="pt-32 pb-20">
          <div className="max-w-7xl mx-auto px-4">
            <div className="max-w-4xl mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="space-y-8"
              >


                <h1 className="text-5xl font-bold leading-tight">
                  Build Knowledge
                  <br />
                  During Every Google Meet
                </h1>
                <p className="text-xl text-gray-400 max-w-3xl mx-auto">
                AI assistant that enhances meetings in real-time and builds a unified knowledge base for quick insights and deep research.
                </p>
                <div className="pt-4 flex justify-center items-center gap-4">
                  <a 
                    href="https://chromewebstore.google.com/detail/vexa-real-time-ai-meeting/ihibgadfkbefnclpbhdlpahfiejhfibl"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group bg-zinc-800 hover:bg-zinc-700 text-white px-8 py-4 rounded-xl transition-all flex items-center gap-2"
                  >
                    Add Vexa Chrome Extension 
                    <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                  </a>
                  

                </div>
                <p className="text-gray-500 pt-4">• No bots joining your calls •</p>

                {/* Add the hero image */}
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="mt-12 relative w-full max-w-5xl mx-auto"
                >
                  <img
                    src="./assets/main_layout.png"
                    alt="Vexa Dashboard Interface"
                    className="w-full h-auto rounded-xl shadow-2xl"
                  />
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Add FeatureSection here */}
        <FeatureSection
          title="Real-Time Meeting Intelligence"
          subtitle="Get instant insights and actionable summaries during your Google Meet calls"
          items={[
            {
              title: "Live Meeting Summary",
              description: "Get real-time summaries of your meetings as they happen, helping you stay focused and catch up quickly if you join late.",
              videoSegment: {
                startSecond: 55,
                endSecond: 80
              }
            },
            {
              title: "Action Items Detection",
              description: "Automatically identify and track action items and decisions made during the meeting.",
              videoSegment: {
                startSecond: 185,
                endSecond: 200
              }
            },
            {
              title: "Recap Speaker Self-Presentation",
              description: "Get a quick summary of the speaker's self-presentation, including their name, title, and company.",
              videoSegment: {
                startSecond: 258,
                endSecond: 275
              }
            },
            {
              title: "Get creative ideas on problem solving",
              description: "Get a quick summary of the speaker's self-presentation, including their name, title, and company.",
              videoSegment: {
                startSecond: 347,
                endSecond: 366
              }
            }
          ]}
          video={{
            id: "k6RBap5l35c",
            isPortrait: true,
            defaultSegment: {
              startSecond: 0,
              endSecond: 15
            }
          }}
          videoOnRight={false}
        />



         {/* Add FeatureSection here */}
         <FeatureSection
          title="Meeting Dashboard"
          subtitle="Access your meeting history and insights at any time"
          items={[
            {
              title: "Recall Meeting Details",
              description: "Meeting from bird's eye view and dive into details in seconds",
              videoSegment: {
                startSecond: 0,
                endSecond: 8
              }
            },
            {
              title: "Create with AI",
              description: "Follow-up emails, documents, and more with AI",
              videoSegment: {
                startSecond: 8,
                endSecond: 17
              }
              
            },
            {
              title: "Copy to clipboard and paste anywhere",
              description: "Copy reports and paste them anywhere you want",
              videoSegment: {
                startSecond: 46,
                endSecond: 54
              }
            }
          ]}
          video={{
            id: "x4xoI2h6oTg",
            isPortrait: false,
            defaultSegment: {
              startSecond: 0,
              endSecond: 15
            }
          }}
          videoOnRight={true}
        />

                 {/* Add FeatureSection here */}
                 <FeatureSection
          title="Access Knowledge Base"
          subtitle="Research collected knowledge from your meetings in chat"
          items={[
            {
              title: "Generate reports based on entirity of the meetings",
              description: "Comprehensive report entirely flexible to your needs",
              videoSegment: {
                startSecond: 19,
                endSecond: 43
              }
            },
            {
              title: "Find anything in the meetings",
              description: "Search through all your meetings and find anything you want",
              videoSegment: {
                startSecond: 64,
                endSecond: 87
              }
            },
            

          ]}
          video={{
            id: "x4xoI2h6oTg",
            isPortrait: false,
            defaultSegment: {
              startSecond: 0,
              endSecond: 15
            }
          }}
          videoOnRight={true}
        />


        


        {/* After ValuePropositions and before Testimonials */}
        <section className="py-20">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="flex flex-col items-center text-center space-y-8"
            >
              <div className="relative w-32 h-32 rounded-full overflow-hidden">
                <img
                  src="https://media.licdn.com/dms/image/v2/C4D03AQFXWMxI1np6hg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1647969193758?e=1737590400&v=beta&t=vEboklZW9TtSbmOGjU4upi45vW00HsGOMZHM1jJD_Yw"
                  alt="Founder"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="space-y-4">
                <h2 className="text-3xl font-bold">Meet the Founder</h2>
                <p className="text-zinc-300 max-w-2xl mx-auto">
                  Building the future of meeting intelligence to help teams collaborate more effectively.
                </p>
                <a
                  href="https://www.linkedin.com/in/dmitry-grankin/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 bg-zinc-800 hover:bg-zinc-700 text-white px-6 py-3 rounded-xl transition-all"
                >
                  <Linkedin className="w-5 h-5" />
                  Connect on LinkedIn
                </a>
              </div>
            </motion.div>
          </div>
        </section>

        {/* <Testimonials /> */}

        <div className="flex items-center justify-center gap-2 mt-4">
                    <span className="text-lg text-gray-400">Designed for</span>
                    <div className="flex items-center gap-2 bg-black">
                      <img
                        src="./assets/google-meet-logo.png"
                        alt="Google Meet"
                        width={120}
                        height={40}
                        className="object-contain"
                      />
          </div>
        </div>

        {/* Value Propositions */}
        <ValuePropositions />

        {/* Add Footer */}
        <footer className="relative py-8 mt-20 border-t border-zinc-800">
          {/* Background lines */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <svg className="absolute w-full h-full opacity-10" viewBox="0 0 100 100" preserveAspectRatio="none">
              <line x1="0" y1="100" x2="100" y2="0" stroke="white" strokeWidth="0.1" vectorEffect="non-scaling-stroke" />
              <line x1="0" y1="0" x2="100" y2="100" stroke="white" strokeWidth="0.1" vectorEffect="non-scaling-stroke" />
            </svg>
          </div>

          <div className="relative max-w-7xl mx-auto px-4">
            {/* Main footer content */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-16 gap-y-8 mb-12">
              {/* Company Info */}
              <div className="space-y-2">
                <div className="flex items-center gap-3">
                  <img 
                    src="./assets/logodark.svg" 
                    alt="Vexa Logo" 
                    className="h-8 w-8"
                  />
                  <span className="text-3xl font-semibold text-white">Vexa</span>
                </div>
                <p className="text-[14px] text-zinc-300">
                  Building the future of meeting<br />intelligence
                </p>
              </div>

              {/* Product */}
              <div>
                <h3 className="text-white font-semibold mb-4">Product</h3>
                <ul className="space-y-2.5 text-[14px]">
                  <li>
                    <a 
                      href="https://chromewebstore.google.com/detail/vexa-real-time-ai-meeting/ihibgadfkbefnclpbhdlpahfiejhfibl" 
                      className="text-zinc-300 hover:text-white transition-colors duration-200"
                    >
                      Chrome Extension
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/app" 
                      className="text-zinc-300 hover:text-white transition-colors duration-200"
                    >
                      Dashboard
                    </a>
                  </li>
                </ul>
              </div>

              {/* Legal */}
              <div>
                <h3 className="text-white font-semibold mb-4">Legal</h3>
                <ul className="space-y-2.5 text-[14px]">
                  <li>
                    <a 
                      href="/privacy-policy" 
                      className="text-zinc-300 hover:text-white transition-colors duration-200"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/terms-of-use" 
                      className="text-zinc-300 hover:text-white transition-colors duration-200"
                    >
                      Terms of Use
                    </a>
                  </li>
                  <li>
                    <a 
                      href="/privacy-policy#cookies" 
                      className="text-zinc-300 hover:text-white transition-colors duration-200"
                    >
                      Cookie Policy
                    </a>
                  </li>
                </ul>
              </div>

              {/* Contact */}
              <div>
                <h3 className="text-white font-semibold mb-4">Contact</h3>
                <ul className="space-y-2.5 text-[14px]">
                  <li>
                    <a 
                      href="mailto:support@vexa.ai" 
                      className="text-zinc-300 hover:text-white transition-colors duration-200"
                    >
                      support@vexa.ai
                    </a>
                  </li>
                  <li>
                    <a 
                      href="https://www.producthunt.com/posts/vexa" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-zinc-300 hover:text-white transition-colors duration-200"
                    >
                      Product Hunt
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Bottom section */}
            <div className="flex flex-col md:flex-row justify-between items-center gap-4 pt-8 border-t border-zinc-800">
              <div className="text-[14px] text-zinc-300">
                © {new Date().getFullYear()} Vexa. All rights reserved.
              </div>
              <div className="flex items-center gap-3">
                <span className="text-[14px] text-zinc-300">Designed for</span>
                <img
                  src="./assets/google-meet-logo.png"
                  alt="Google Meet"
                  className="h-[40px] w-auto opacity-90 grayscale"
                />
              </div>
            </div>
          </div>
        </footer>
      </div>

      <CookieConsent />
    </div>
  );
};



export default LandingPage;