import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Clock, FileText, ChevronUp, ChevronDown, Users, Lock, Share2, Check, Copy } from 'lucide-react';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { API_BASE_URL } from '../config';

interface IndexRequestResponse {
  status: 'already_processing' | 'queued' | 'already_queued';
}

interface MeetingCardProps {
  meeting: {
    meeting_id: string;
    meeting_name: string;
    timestamp: string;
    speakers: string[];
    meeting_summary?: string;
    is_indexed: boolean;
    is_owner: boolean;
    access_level: string;
  };
  token: string;
  isSelected: boolean;
  showSummary: boolean;
  onExpandAndSelect: () => void;
  onTranscriptOpen: () => void;
  discussionPoints: any[];
  onIndexRequest?: () => Promise<IndexRequestResponse>;
}

const MeetingCard: React.FC<MeetingCardProps> = ({
  meeting,
  token,
  isSelected,
  showSummary,
  onExpandAndSelect,
  onTranscriptOpen,
  discussionPoints,
  onIndexRequest,
}) => {
  const [isIndexing, setIsIndexing] = React.useState(false);
  const [indexStatus, setIndexStatus] = React.useState<'not_indexed' | 'processing' | 'queued'>('not_indexed');
  const [activePointId, setActivePointId] = useState<number | null>(null);
  const [isSharing, setIsSharing] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);

  const handleIndexClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!onIndexRequest || isIndexing) return;
    
    setIsIndexing(true);
    try {
      const response = await onIndexRequest();
      if (response?.status === 'already_processing' || response?.status === 'queued') {
        setIndexStatus('processing');
      } else if (response?.status === 'already_queued') {
        setIndexStatus('queued');
      }
    } catch (error) {
      console.error('Failed to index meeting:', error);
      setIndexStatus('not_indexed');
    } finally {
      setIsIndexing(false);
    }
  };

  const handleShare = async (e: React.MouseEvent) => {
    e.stopPropagation();
    
    setIsSharing(true);
    try {
      const response = await fetch(`${API_BASE_URL}/share-links`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          meeting_ids: [meeting.meeting_id],
          access_level: 'transcript',
          expiration_hours: 24,
          target_email: null
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to create share link: ${response.statusText}`);
      }

      const { token: shareToken } = await response.json();
      const shareUrl = `${window.location.origin}/share/${shareToken}`;
      
      await navigator.clipboard.writeText(shareUrl);
      setShareSuccess(true);
      setTimeout(() => setShareSuccess(false), 2000);
    } catch (error) {
      console.error('Error sharing meeting:', error);
    } finally {
      setIsSharing(false);
    }
  };

  const getIndexingDisplay = () => {
    if (isIndexing) {
      return (
        <>
          <Loader2 className="w-3 h-3 animate-spin" />
          Indexing...
        </>
      );
    }
    
    if (indexStatus === 'processing' || indexStatus === 'queued') {
      return (
        <>
          <Loader2 className="w-3 h-3 animate-spin" />
          {indexStatus === 'processing' ? 'Processing...' : 'Queued...'}
        </>
      );
    }

    return (
      <div className="flex items-center gap-1.5">
        <span className="w-1.5 h-1.5 rounded-full bg-yellow-500 animate-pulse" />
        <span className="font-medium">Request Indexing</span>
      </div>
    );
  };

  return (
    <div
      className={`bg-white dark:bg-card/95 rounded-lg border transition-all duration-200 ${
        isSelected 
          ? 'border-primary/40 bg-primary/[0.03]'
          : 'border-border/40 hover:border-border/60 hover:bg-muted/[0.02]'
      }`}
    >
      <div 
        className={`p-2.5 cursor-pointer ${
          isSelected ? 'bg-primary/[0.02]' : ''
        }`}
        onClick={onExpandAndSelect}
      >
        <div className="flex items-center justify-between gap-2">
          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between gap-2">
              <h3 className="font-medium text-sm truncate flex items-center gap-1.5 text-foreground/90">
                {meeting.meeting_name}
                {!meeting.is_owner && (
                  <span 
                    className="inline-flex items-center text-blue-600/80 hover:text-blue-600" 
                    title={`Shared meeting (${meeting.access_level} access)`}
                  >
                    <Users className="w-3 h-3" />
                  </span>
                )}
              </h3>
              {!meeting.is_indexed && (
                <span 
                  onClick={handleIndexClick}
                  className={`px-1.5 py-0.5 rounded-full text-[10px] font-medium inline-flex items-center gap-1 
                    ${(isIndexing || indexStatus !== 'not_indexed') ? 'bg-blue-50 text-blue-700' : 'bg-yellow-50 text-yellow-700'} 
                    ${onIndexRequest && indexStatus === 'not_indexed' ? 'cursor-pointer hover:bg-opacity-80' : ''} 
                    transition-colors`}
                >
                  {getIndexingDisplay()}
                </span>
              )}
            </div>
            
            <div className="flex items-center gap-3 mt-0.5">
              <div className="flex items-center gap-1 text-xs text-muted-foreground/90">
                <Clock className="w-3 h-3" />
                <span>
                  {new Date(meeting.timestamp).toLocaleString([], {
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </span>
              </div>
              <div className="flex items-center gap-1.5">
                <div className="flex flex-wrap gap-1">
                  {meeting.speakers.map((speaker, idx) => (
                    <span
                      key={idx}
                      className="inline-flex items-center px-1.5 py-0.5 rounded-md text-[10px] 
                        bg-muted/40 text-muted-foreground/80 font-medium"
                    >
                      {speaker}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-1">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onTranscriptOpen();
              }}
              className="p-1.5 rounded-md hover:bg-muted/80 active:bg-muted/90 transition-colors"
            >
              <FileText className="w-3.5 h-3.5 text-muted-foreground/90" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onExpandAndSelect();
              }}
              className="p-1.5 rounded-md hover:bg-gray-100 transition-colors"
            >
              {showSummary ? (
                <ChevronUp className="w-3.5 h-3.5 text-gray-500" />
              ) : (
                <ChevronDown className="w-3.5 h-3.5 text-gray-500" />
              )}
            </button>
            <button
              onClick={handleShare}
              className={`p-1.5 rounded-md transition-colors ${
                isSharing ? 'bg-gray-100 cursor-wait' : 
                shareSuccess ? 'bg-green-100 text-green-600' : 
                'hover:bg-gray-100'
              }`}
              disabled={isSharing}
              title="Share meeting"
            >
              {isSharing ? (
                <Loader2 className="w-3.5 h-3.5 animate-spin" />
              ) : shareSuccess ? (
                <Check className="w-3.5 h-3.5" />
              ) : (
                <Share2 className="w-3.5 h-3.5 text-gray-500" />
              )}
            </button>
          </div>
        </div>
      </div>

      {showSummary && meeting.is_indexed && (
        <div className="px-3 py-2 border-t bg-muted/[0.06] text-sm">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              p: ({node, ...props}) => (
                <p className="text-xs text-foreground/85 mb-2 last:mb-0" {...props} />
              )
            }}
          >
            {meeting.meeting_summary || ''}
          </ReactMarkdown>
          
          {discussionPoints?.length > 0 && (
            <div className="flex flex-wrap gap-1.5 mt-2 pt-2 border-t relative">
              {discussionPoints.map((point, index) => (
                <div key={index}>
                  <div 
                    className="px-2 py-0.5 rounded-full text-[10px] 
                      bg-primary/[0.08] text-primary/90 hover:bg-primary/[0.12] 
                      cursor-pointer transition-colors"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActivePointId(activePointId === index ? null : index);
                    }}
                  >
                    {point.topic_name}
                  </div>
                  {activePointId === index && (
                    <div className="absolute z-10 bg-white border rounded-lg shadow-lg p-2 mt-1 text-xs max-w-xs">
                      <div className="font-medium">{point.topic_type}</div>
                      <div className="mt-1">{point.summary}</div>
                      {point.details && <div className="mt-1 text-gray-600">{point.details}</div>}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MeetingCard;