import { useMemo, useEffect } from 'react';

interface Meeting {
  meeting_id: string;
  meeting_name: string;
  timestamp: string;
  speakers: string[];
  meeting_summary?: string;
}

interface MeetingModeState {
  mode: 'single' | 'multiple';
  selectedIds: string[];
  display: {
    title: string;
    subtitle?: string;
  };
  api: {
    endpoint: string;
    body: {
      meeting_ids: string[];
      speaker?: string | string[];
    };
  };
}

export const useMeetingMode = (
  meetings: Meeting[],
  expandedMeetingId: string | null,
  speaker?: string | string[]
): MeetingModeState => {
  console.log('🎯 useMeetingMode Called', {
    meetingsCount: meetings.length,
    expandedMeetingId,
    speaker
  });

  useEffect(() => {
    console.group('🎯 Meeting Mode Props Changed');
    console.log('Meetings:', meetings.map(m => ({ id: m.meeting_id, name: m.meeting_name })));
    console.log('Expanded Meeting:', expandedMeetingId);
    console.log('Speaker:', speaker);
    console.groupEnd();
  }, [meetings, expandedMeetingId, speaker]);

  return useMemo(() => {
    const state = expandedMeetingId ? {
      mode: 'single' as const,
      selectedIds: [expandedMeetingId],
      display: {
        title: meetings.find(m => m.meeting_id === expandedMeetingId)?.meeting_name || 'Selected Meeting',
        subtitle: meetings.find(m => m.meeting_id === expandedMeetingId)?.timestamp
      },
      api: {
        endpoint: '/chat/meeting',
        body: { meeting_ids: [expandedMeetingId] }
      }
    } : {
      mode: 'multiple' as const,
      selectedIds: meetings.map(m => m.meeting_id),
      display: {
        title: speaker 
          ? Array.isArray(speaker) 
            ? `Meetings with ${speaker.length} speakers`
            : `Meetings with ${speaker}`
          : `All Meetings (${meetings.length})`
      },
      api: {
        endpoint: '/chat/meeting/summary',
        body: {
          meeting_ids: meetings.map(m => m.meeting_id),
          speaker
        }
      }
    };

    console.group('🎯 Meeting Mode State Updated');
    console.log('Mode:', state.mode);
    console.log('Selected IDs:', state.selectedIds);
    console.log('Display:', state.display);
    console.log('API Config:', state.api);
    console.groupEnd();

    return state;
  }, [meetings, expandedMeetingId, speaker]);
}; 