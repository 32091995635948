import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MarkdownRendererProps {
  content: string;
  className?: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content, className }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={`prose prose-slate dark:prose-invert max-w-none ${className}`}
      components={{
        // Headings with proper hierarchy and spacing
        h1: ({ node, ...props }) => (
          <h1 className="text-3xl font-bold mb-6 text-primary tracking-tight" {...props} />
        ),
        h2: ({ node, ...props }) => (
          <h2 className="text-2xl font-semibold mb-4 text-primary/90 tracking-tight mt-8" {...props} />
        ),
        h3: ({ node, ...props }) => (
          <h3 className="text-xl font-semibold mb-3 text-primary/90 tracking-tight mt-6" {...props} />
        ),
        h4: ({ node, ...props }) => (
          <h4 className="text-lg font-semibold mb-2 text-primary/80 tracking-tight mt-4" {...props} />
        ),

        // Paragraph with improved readability
        p: ({ node, ...props }) => (
          <p className="text-base leading-7 mb-4 text-secondary-foreground" {...props} />
        ),

        // Lists with proper spacing and bullets
        ul: ({ node, ...props }) => (
          <ul className="list-disc list-outside ml-6 mb-4 space-y-2" {...props} />
        ),
        ol: ({ node, ...props }) => (
          <ol className="list-decimal list-outside ml-6 mb-4 space-y-2" {...props} />
        ),
        li: ({ node, ...props }) => (
          <li className="text-secondary-foreground leading-7" {...props} />
        ),

        // Enhanced code blocks
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <div className="rounded-lg overflow-hidden my-4">
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={tomorrow}
                language={match[1]}
                PreTag="div"
                className="!bg-muted/50 !mt-0"
                customStyle={{
                  padding: '1rem',
                  borderRadius: '0.5rem',
                  fontSize: '0.875rem',
                }}
                {...props}
              />
            </div>
          ) : (
            <code className="px-1.5 py-0.5 rounded-md bg-muted/50 text-primary font-mono text-sm" {...props}>
              {children}
            </code>
          );
        },

        // Links with better hover states
        a: ({ node, ...props }) => (
          <a 
            className="text-primary underline decoration-primary/30 underline-offset-2 hover:decoration-primary/60 transition-all" 
            {...props}
          />
        ),

        // Blockquotes with visual emphasis
        blockquote: ({ node, ...props }) => (
          <blockquote 
            className="border-l-4 border-primary/20 pl-4 my-4 italic text-muted-foreground"
            {...props}
          />
        ),

        // Enhanced table styling
        table: ({ node, ...props }) => (
          <div className="my-4 w-full overflow-x-auto rounded-lg border border-border">
            <table className="w-full border-collapse" {...props} />
          </div>
        ),
        thead: ({ node, ...props }) => (
          <thead className="bg-muted/50" {...props} />
        ),
        tbody: ({ node, ...props }) => (
          <tbody className="divide-y divide-border bg-card" {...props} />
        ),
        tr: ({ node, ...props }) => (
          <tr className="transition-colors hover:bg-muted/30" {...props} />
        ),
        th: ({ node, ...props }) => (
          <th className="px-4 py-3 text-left text-xs font-medium text-muted-foreground uppercase tracking-wider" {...props} />
        ),
        td: ({ node, ...props }) => (
          <td className="px-4 py-3 text-sm text-secondary-foreground whitespace-normal break-words" {...props} />
        ),

        // Strong text with better emphasis
        strong: ({ node, ...props }) => (
          <strong className="font-semibold text-primary" {...props} />
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer; 