import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useChat } from '../hooks/useChat';
import ReactMarkdown from 'react-markdown';
import { Meeting, SearchResult, Thread, MeetingModeState, ChatHookConfig } from '../types';
import FilterChatPanel from './FilterChatPanel';
import remarkGfm from 'remark-gfm';
import TranscriptPanel from './TranscriptPanel';
import { API_BASE_URL } from '../config';
import MeetingCard from './MeetingCard';
import { useMeetingSelection } from '../hooks/useMeetingSelection';
import { useMeetingMode } from '../hooks/useMeetingMode';
import { Search, X } from 'lucide-react';
import debounce from 'lodash/debounce';
import SearchInput from './SearchInput';
import { Loader2 } from 'lucide-react';

interface FilterChatAreaProps {
  speaker?: string | string[];
  token: string;
  onChatComplete: () => void;
  meetings: Meeting[];
  onMeetingSelect?: (meetingId: string) => void;
  onGlobalSearch?: (query: string) => void;
  onClearSpeakers?: () => void;
}

interface GroupedMeetings {
  [date: string]: Meeting[];
}

interface IndexingResponse {
  status: 'queued' | 'already_processing' | 'already_queued';
  message: string;
}

interface PaginationState {
  offset: number;
  hasMore: boolean;
  isLoading: boolean;
}

const FilterChatArea: React.FC<FilterChatAreaProps> = ({ 
  speaker, 
  token, 
  onChatComplete, 
  meetings = [],
  onMeetingSelect,
  onGlobalSearch,
  onClearSpeakers
}) => {
  console.log('🚀 FilterChatArea Mounted/Updated', {
    speaker,
    meetingsCount: meetings.length,
    token: token ? 'present' : 'missing'
  });

  const [inputMessage, setInputMessage] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [leftPanelWidth, setLeftPanelWidth] = useState('45%');
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef<{
    startX: number;
    startWidth: number;
    element: HTMLElement | null;
  }>({ startX: 0, startWidth: 50, element: null });
  const [collapsedMeetings, setCollapsedMeetings] = useState<Record<string, boolean>>({});
  const [selectedMeetingId, setSelectedMeetingId] = useState<string | null>(null);
  const [chatMeetingId, setChatMeetingId] = useState<string | null>(null);
  const [shownSummaries, setShownSummaries] = useState<Record<string, boolean>>({});
  const [meetingDiscussionPoints, setMeetingDiscussionPoints] = useState<Record<string, any>>({});
  const [isFullRefresh, setIsFullRefresh] = useState(false);
  const [expandedMeetingId, setExpandedMeetingId] = useState<string | null>(null);
  const [globalSearchResults, setGlobalSearchResults] = useState<Meeting[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [filteredMeetings, setFilteredMeetings] = useState(meetings);
  const [activeFilterType, setActiveFilterType] = useState<'none' | 'speakers' | 'search'>('none');
  const [pageSize] = useState(20);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const [totalMeetings, setTotalMeetings] = useState(0);

  // Separate pagination states for each mode
  const [allMeetingsPagination, setAllMeetingsPagination] = useState<PaginationState>({
    offset: 0,
    hasMore: true,
    isLoading: false
  });

  const [speakerMeetingsPagination, setSpeakerMeetingsPagination] = useState<PaginationState>({
    offset: 0,
    hasMore: true,
    isLoading: false
  });

  // Reset meeting selections when speaker changes
  useEffect(() => {
    console.log('🔄 Speaker changed, resetting meeting selections:', speaker);
    setChatMeetingId(null);
    setExpandedMeetingId(null);
  }, [speaker]);

  const chatConfig = useMeetingSelection({
    meetings,
    selectedMeetingId: chatMeetingId,
    speaker
  });

  const meetingMode = useMeetingMode(meetings, expandedMeetingId, speaker);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    const container = document.getElementById('chat-container');
    if (!container) return;

    setIsDragging(true);
    dragRef.current = {
      startX: e.pageX,
      startWidth: container.offsetWidth * 0.5,
      element: container
    };
  };

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!isDragging || !dragRef.current.element) return;
    
    const delta = e.pageX - dragRef.current.startX;
    const containerWidth = dragRef.current.element.offsetWidth;
    const newWidth = ((dragRef.current.startWidth + delta) / containerWidth) * 100;
    const constrainedWidth = Math.min(Math.max(newWidth, 20), 80);
    
    // Use requestAnimationFrame for smooth updates
    requestAnimationFrame(() => {
      const leftPanel = dragRef.current.element?.querySelector('.left-panel') as HTMLElement;
      if (leftPanel) {
        leftPanel.style.width = `${constrainedWidth}%`;
      }
    });
  }, [isDragging]);

  const handleMouseUp = useCallback(() => {
    if (!isDragging) return;
    
    const leftPanel = dragRef.current.element?.querySelector('.left-panel') as HTMLElement;
    if (leftPanel) {
      const finalWidth = leftPanel.offsetWidth;
      const containerWidth = dragRef.current.element?.offsetWidth || 1;
      const percentage = (finalWidth / containerWidth) * 100;
      setLeftPanelWidth(`${percentage}%`);
    }
    
    setIsDragging(false);
    dragRef.current = { startX: 0, startWidth: 0, element: null };
  }, [isDragging]);

  useEffect(() => {
    if (isDragging) {
      document.body.style.userSelect = 'none';
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    
    return () => {
      document.body.style.userSelect = '';
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const { 
    messages, 
    isLoading, 
    sendMessage,
    threads,
    handleThreadSelect,
    startNewConversation,
    fetchAllThreads,
    fetchMeetingThreads
  } = useChat({ 
    token,
    onSearchResults: setSearchResults,
    endpoint: meetingMode.api.endpoint,
    additionalBody: meetingMode.api.body,
    threadId: selectedThreadId,
    onThreadCreated: () => {
      // Handle thread creation if needed
    }
  } as ChatHookConfig);

  useEffect(() => {
    fetchAllThreads();
  }, [fetchAllThreads]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    sendMessage(inputMessage);
    setInputMessage('');
  };

  const fetchMeetingDetails = useCallback(async (meetingId: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/meeting/${meetingId}/details`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch meeting details');
      }

      const data = await response.json();
      
      // Update the meeting in filteredMeetings with the full details
      setFilteredMeetings(prev => prev.map(meeting => 
        meeting.meeting_id === meetingId 
          ? { ...meeting, meeting_summary: data.meeting_summary }
          : meeting
      ));

      // Store discussion points
      setMeetingDiscussionPoints(prev => ({
        ...prev,
        [meetingId]: data.discussion_points || []
      }));

    } catch (error) {
      console.error('Failed to fetch meeting details:', error);
    }
  }, [token]);

  const handleMeetingExpandAndSelect = useCallback((meetingId: string) => {
    console.group('🎯 Meeting Selection Change');
    console.log('Previous expanded:', expandedMeetingId);
    console.log('New selection:', meetingId);
    console.groupEnd();

    // If a transcript is currently open, update it to show the newly selected meeting
    if (selectedMeetingId !== null) {
      setSelectedMeetingId(meetingId);
    }

    if (expandedMeetingId === meetingId) {
      setExpandedMeetingId(null);
      fetchAllThreads(); // Reset to global threads
    } else {
      setExpandedMeetingId(meetingId);
      fetchMeetingDetails(meetingId);
      
      // Fetch meeting-specific threads
      const loadMeetingThreads = async () => {
        const latestThreadId = await fetchMeetingThreads(meetingId);
        if (latestThreadId) {
          setSelectedThreadId(latestThreadId);
        } else {
          startNewConversation();
        }
      };
      loadMeetingThreads();
    }
  }, [expandedMeetingId, selectedMeetingId, fetchMeetingDetails, fetchMeetingThreads, startNewConversation, fetchAllThreads]);

  const handleTranscriptOpen = useCallback((meetingId: string) => {
    // First close current transcript if any
    setSelectedMeetingId(null);
    
    // Then set the new meeting ID after a brief delay
    // This ensures proper unmount/remount cycle
    setTimeout(() => {
      setSelectedMeetingId(meetingId);
    }, 50);
  }, []);

  const toggleCollapse = (meetingId: string) => {
    setCollapsedMeetings(prev => ({
      ...prev,
      [meetingId]: !prev[meetingId]
    }));
  };

  const toggleAll = (collapse: boolean) => {
    const newState = meetings.reduce((acc, meeting) => ({
      ...acc,
      [meeting.meeting_id]: collapse
    }), {});
    setCollapsedMeetings(newState);
  };

  const groupMeetingsByDate = (meetings: Meeting[]) => {
    // First, deduplicate meetings by ID
    const uniqueMeetings = Array.from(
      new Map(meetings.map(meeting => [meeting.meeting_id, meeting])).values()
    );

    // Then group by date
    return uniqueMeetings.reduce((groups: GroupedMeetings, meeting) => {
      const date = new Date(meeting.timestamp).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(meeting);
      return groups;
    }, {});
  };

  const sortedDates = useCallback(() => {
    const dates = Object.keys(groupMeetingsByDate(meetings));
    return dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  }, [groupMeetingsByDate, meetings]);

  const formatSpeakerDisplay = useCallback(() => {
    if (!speaker) return 'All Meetings';
    const speakers = Array.isArray(speaker) ? speaker : [speaker];
    if (speakers.length === 0) return 'All Meetings';
    if (speakers.length === 1) return `Meetings with ${speakers[0]}`;
    if (speakers.length === 2) return `Meetings with ${speakers[0]} and ${speakers[1]}`;
    return `Meetings with ${speakers.length} speakers`;
  }, [speaker]);

  const fetchDiscussionPoints = useCallback(async (meetingId: string) => {
    console.group('📋 Fetching Discussion Points');
    console.log('Meeting ID:', meetingId);
    try {
      const response = await fetch(`${API_BASE_URL}/meeting/${meetingId}/details`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      console.log('Response:', data);
      setMeetingDiscussionPoints(prev => {
        console.log('Previous points:', prev);
        const updated = {
          ...prev,
          [meetingId]: data.discussion_points || []
        };
        console.log('Updated points:', updated);
        return updated;
      });
    } catch (error) {
      console.error('Failed to fetch discussion points:', error);
    }
    console.groupEnd();
  }, [token]);

  const closeTranscript = useCallback(() => {
    if (isFullRefresh) {
      setSelectedMeetingId(null);
    }
  }, [isFullRefresh]);

  useEffect(() => {
    closeTranscript();
  }, [meetings, closeTranscript]);

  useEffect(() => {
    if (isFullRefresh) {
      setIsFullRefresh(false);
    }
  }, [isFullRefresh]);

  const handleMeetingsRefresh = useCallback(() => {
    setIsFullRefresh(true);
  }, []);

  useEffect(() => {
    if (expandedMeetingId) {
      onMeetingSelect?.(expandedMeetingId);
      fetchDiscussionPoints(expandedMeetingId);
    }
  }, [expandedMeetingId, onMeetingSelect, fetchDiscussionPoints]);

  // Add logging for initial props
  useEffect(() => {
    console.group('🔄 FilterChatArea Props');
    console.log('Speaker:', speaker);
    console.log('Meetings:', meetings.length);
    console.log('Meeting IDs:', meetings.map(m => m.meeting_id));
    console.groupEnd();
  }, [speaker, meetings]);

  const handleIndexRequest = useCallback(async (meetingId: string): Promise<IndexingResponse> => {
    try {
      const response = await fetch(`${API_BASE_URL}/meetings/${meetingId}/index`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to index meeting');
      }

      return await response.json();
    } catch (error) {
      console.error('Error indexing meeting:', error);
      // Return a default response to match the expected type
      return { 
        status: 'queued',
        message: 'Indexing request queued' 
      };
    }
  }, [token]);

  const handleSearch = useCallback(async (query: string) => {
    if (!query?.trim()) {
      setGlobalSearchResults([]);
      setSearchActive(false);
      setActiveFilterType('none');
      return;
    }

    setIsSearching(true);
    setSearchActive(true);
    setActiveFilterType('search');
    
    // Reset pagination states when searching
    setSpeakerMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
    setAllMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
    
    try {
      const response = await fetch(`${API_BASE_URL}/search/global`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: query.trim(),
          limit: 20,
          min_score: 0.4
        })
      });

      if (!response.ok) {
        throw new Error(`Search failed with status: ${response.status}`);
      }
      
      const data = await response.json();
      const results = data.meetings || [];
      const typedResults: Meeting[] = results.map((result: any) => ({
        ...result,
        is_indexed: result.is_indexed || false,
        is_owner: result.is_owner || false,
        access_level: result.access_level || 'read'
      }));
      setGlobalSearchResults(typedResults);
    } catch (error) {
      console.error('❌ Search failed:', error);
      setGlobalSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, [token]);

  // Load all meetings
  const loadAllMeetings = useCallback(async () => {
    if (allMeetingsPagination.isLoading || !allMeetingsPagination.hasMore) return;

    setAllMeetingsPagination(prev => ({ ...prev, isLoading: true }));
    try {
      const response = await fetch(
        `${API_BASE_URL}/meetings/all?offset=${allMeetingsPagination.offset}&limit=${pageSize}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch meetings');

      const data = await response.json();
      const newMeetings = data.meetings || [];
      setTotalMeetings(data.total);
      
      setFilteredMeetings(prev => [...prev, ...newMeetings]);
      setAllMeetingsPagination(prev => ({
        offset: prev.offset + pageSize,
        hasMore: filteredMeetings.length + newMeetings.length < data.total,
        isLoading: false
      }));
    } catch (error) {
      console.error('Failed to load meetings:', error);
      setAllMeetingsPagination(prev => ({ ...prev, isLoading: false }));
    }
  }, [token, pageSize, allMeetingsPagination, filteredMeetings.length]);

  // Load meetings by speakers
  const loadSpeakerMeetings = useCallback(async () => {
    if (!speaker) {
      // If no speaker is selected, just return without doing anything
      // The effect will handle the reset
      return;
    }

    if (speakerMeetingsPagination.isLoading || !speakerMeetingsPagination.hasMore) return;

    setSpeakerMeetingsPagination(prev => ({ ...prev, isLoading: true }));
    try {
      const speakers = Array.isArray(speaker) ? speaker : [speaker];
      const response = await fetch(`${API_BASE_URL}/meetings/by-speakers`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          speakers,
          limit: pageSize,
          offset: speakerMeetingsPagination.offset
        })
      });

      if (!response.ok) throw new Error('Failed to fetch speaker meetings');

      const data = await response.json();
      const newMeetings = data.meetings || [];
      setTotalMeetings(data.total);
      
      setFilteredMeetings(prev => [...prev, ...newMeetings]);
      setSpeakerMeetingsPagination(prev => ({
        offset: prev.offset + pageSize,
        hasMore: filteredMeetings.length + newMeetings.length < data.total,
        isLoading: false
      }));
    } catch (error) {
      console.error('Failed to load speaker meetings:', error);
      setSpeakerMeetingsPagination(prev => ({ ...prev, isLoading: false }));
    }
  }, [speaker, token, pageSize, speakerMeetingsPagination, filteredMeetings.length]);

  // Update the initial load effect
  useEffect(() => {
    setFilteredMeetings([]); // Clear existing meetings
    setActiveFilterType(speaker ? 'speakers' : 'none'); // Set correct filter type
    
    // Reset appropriate pagination state
    if (speaker) {
      setSpeakerMeetingsPagination(prev => {
        if (prev.offset === 0) return prev;
        return { offset: 0, hasMore: true, isLoading: false };
      });
    } else {
      setAllMeetingsPagination(prev => {
        if (prev.offset === 0) return prev;
        return { offset: 0, hasMore: true, isLoading: false };
      });
    }
  }, [speaker]); // Only depend on speaker changes

  const handleSpeakerMeetingsSelect = useCallback((selectedSpeaker: string, speakerMeetings: Meeting[]) => {
    console.log('👤 Speaker filter applied:', { 
      selectedSpeaker, 
      meetingsCount: speakerMeetings.length,
      firstMeeting: speakerMeetings[0]?.meeting_name
    });
    
    // Reset states
    setFilteredMeetings([]);
    setSpeakerMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
    setAllMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
    
    // Set filter type and clear search
    setActiveFilterType(selectedSpeaker ? 'speakers' : 'none');
    setSearchActive(false);
    setGlobalSearchResults([]);
  }, []); // No dependencies needed

  // Update loadMore to pass all speakers
  const loadMore = useCallback(async () => {
    if (searchActive || activeFilterType === 'search') return;
    
    if (speaker) {
      await loadSpeakerMeetings();
    } else {
      await loadAllMeetings();
    }
  }, [speaker, searchActive, activeFilterType, loadSpeakerMeetings, loadAllMeetings]);

  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) return;
        
        const currentPagination = speaker ? speakerMeetingsPagination : allMeetingsPagination;
        if (!currentPagination.isLoading && currentPagination.hasMore) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [speaker, speakerMeetingsPagination, allMeetingsPagination, loadMore]);

  // First define displayedMeetings
  const displayedMeetings = useMemo(() => {
    const currentMeetings = (() => {
      switch (activeFilterType) {
        case 'search':
          return globalSearchResults;
        case 'speakers':
        case 'none':
          return filteredMeetings;
        default:
          return filteredMeetings;
      }
    })();

    // Deduplicate meetings by ID
    const uniqueMeetings = Array.from(
      new Map(currentMeetings.map(meeting => [meeting.meeting_id, meeting])).values()
    );

    console.log('📊 Displaying meetings:', {
      filterType: activeFilterType,
      count: uniqueMeetings.length,
      total: totalMeetings,
      firstMeetingId: uniqueMeetings[0]?.meeting_id,
      speaker,
      autoExpanded: uniqueMeetings.length === 1
    });

    return uniqueMeetings;
  }, [activeFilterType, globalSearchResults, filteredMeetings, totalMeetings, speaker]);

  // Then use it in the effect
  useEffect(() => {
    // If there's exactly one meeting displayed, expand it automatically
    if (displayedMeetings.length === 1 && !expandedMeetingId) {
      const singleMeeting = displayedMeetings[0];
      handleMeetingExpandAndSelect(singleMeeting.meeting_id);
    }
  }, [displayedMeetings.length, expandedMeetingId, handleMeetingExpandAndSelect]);

  const handleClearFilters = useCallback(() => {
    console.log('Clearing all filters');
    // Clear expanded meeting
    setExpandedMeetingId(null);
    // Clear chat meeting
    setChatMeetingId(null);
    // Reset to all meetings view
    setActiveFilterType('none');
    // Clear search if active
    setSearchActive(false);
    setGlobalSearchResults([]);
    // Reset pagination and clear meetings
    setFilteredMeetings([]);
    setAllMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
    setSpeakerMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
    // Clear speaker selection in parent
    onClearSpeakers?.();
    // Set panel width for global mode
    setLeftPanelWidth('30%');
  }, [onClearSpeakers]); // Only depend on callback

  // Add effect to handle width changes based on global/speaker mode
  useEffect(() => {
    const isGlobalMode = !speaker && !expandedMeetingId;
    setLeftPanelWidth(isGlobalMode ? '45%' : '50%');
  }, [speaker, expandedMeetingId]);

  // Effect to handle speaker changes - only handles resets
  useEffect(() => {
    // Reset states when speaker changes
    setFilteredMeetings([]); 
    setActiveFilterType(speaker ? 'speakers' : 'none');
    setSpeakerMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
    setAllMeetingsPagination({ offset: 0, hasMore: true, isLoading: false });
  }, [speaker]); // Only depend on speaker changes

  return (
    <div id="chat-container" className="flex-1 flex bg-background relative h-full">
      <div 
        className="left-panel overflow-y-auto p-8"
        style={{ 
          width: leftPanelWidth,
          transition: isDragging ? 'none' : 'width 0.3s ease-out'
        }}
      >
        <div className="max-w-3xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold">
              {searchActive 
                ? `Search Results (${globalSearchResults.length})`
                : `${formatSpeakerDisplay()} (${meetings.length})`}
            </h2>
          </div>

          <div className="mb-6">
            <SearchInput onSearch={handleSearch} />
          </div>

          <div className="space-y-8">
            {isSearching ? (
              <div className="text-center py-4">
                <Loader2 className="w-6 h-6 animate-spin mx-auto" />
                <p className="text-sm text-muted-foreground mt-2">Searching...</p>
              </div>
            ) : searchActive ? (
              <div className="space-y-2">
                {displayedMeetings.map((meeting: Meeting) => (
                  <MeetingCard
                    key={meeting.meeting_id}
                    meeting={meeting}
                    token={token}
                    isSelected={selectedMeetingId === meeting.meeting_id}
                    showSummary={expandedMeetingId === meeting.meeting_id}
                    onExpandAndSelect={() => handleMeetingExpandAndSelect(meeting.meeting_id)}
                    onTranscriptOpen={() => handleTranscriptOpen(meeting.meeting_id)}
                    discussionPoints={meetingDiscussionPoints[meeting.meeting_id] || []}
                    onIndexRequest={async () => {
                      return await handleIndexRequest(meeting.meeting_id);
                    }}
                  />
                ))}
              </div>
            ) : (
              (() => {
                // Get dates from filtered/paginated meetings only
                const groupedMeetings = groupMeetingsByDate(displayedMeetings);
                const dates = Object.keys(groupedMeetings)
                  .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                  // Only show dates that have meetings
                  .filter(date => groupedMeetings[date]?.length > 0);

                return (
                  <>
                    {dates.map(date => (
                      <div key={date} className="space-y-3">
                        <h3 className="font-heading text-sm font-semibold text-primary/80">
                          {date}
                        </h3>
                        <div className="space-y-2">
                          {groupedMeetings[date].map((meeting) => (
                            <MeetingCard
                              key={`${date}-${meeting.meeting_id}`}
                              meeting={meeting}
                              token={token}
                              isSelected={selectedMeetingId === meeting.meeting_id}
                              showSummary={expandedMeetingId === meeting.meeting_id}
                              onExpandAndSelect={() => handleMeetingExpandAndSelect(meeting.meeting_id)}
                              onTranscriptOpen={() => handleTranscriptOpen(meeting.meeting_id)}
                              discussionPoints={meetingDiscussionPoints[meeting.meeting_id] || []}
                              onIndexRequest={async () => {
                                return await handleIndexRequest(meeting.meeting_id);
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ))}

                    {!searchActive && (
                      <div ref={observerTarget} className="h-10">
                        {(speaker ? speakerMeetingsPagination : allMeetingsPagination).isLoading && (
                          <div className="text-center py-4">
                            <Loader2 className="w-6 h-6 animate-spin mx-auto" />
                            <p className="text-sm text-muted-foreground mt-2">
                              Loading more meetings... ({filteredMeetings.length} of {totalMeetings})
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                );
              })()
            )}
          </div>
        </div>
      </div>

      <div
        className="w-1 bg-border hover:bg-primary/50 cursor-col-resize relative"
        onMouseDown={handleMouseDown}
      >
        <div className="absolute inset-y-0 -left-2 right-2" />
      </div>

      {selectedMeetingId && (
        <TranscriptPanel
          meetingId={selectedMeetingId}
          token={token}
          onClose={() => setSelectedMeetingId(null)}
        />
      )}

      <div 
        style={{ width: selectedMeetingId ? '40%' : `calc(100% - ${leftPanelWidth})` }}
        className="border-l border-border bg-muted/30"
      >
        <FilterChatPanel
          messages={messages}
          isLoading={isLoading}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          searchResults={searchResults}
          threads={threads}
          selectedThreadId={selectedThreadId}
          onThreadSelect={handleThreadSelect}
          startNewConversation={startNewConversation}
          speaker={speaker}
          meetingMode={meetingMode}
          selectedMeeting={expandedMeetingId ? meetings.find(m => m.meeting_id === expandedMeetingId) : undefined}
          clearFilters={handleClearFilters}
          onMeetingSelect={(meetingId: string | null) => {
            setExpandedMeetingId(meetingId);
            setChatMeetingId(meetingId);
          }}
        />
      </div>
    </div>
  );
};

export default FilterChatArea;