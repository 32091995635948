import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import { ChatHookConfig, Message, SearchResult, StreamingData, Thread } from '../types';

export const useChat = ({ 
  token, 
  threadId, 
  meetingId,
  onThreadCreated, 
  onSearchResults, 
  endpoint = '/chat',
  additionalBody = {}
}: ChatHookConfig) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [threads, setThreads] = useState<Thread[]>([]);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(threadId);
  const messageQueue = useRef<string[]>([]);
  const isProcessing = useRef(false);
  const accumulatedContent = useRef('');
  const currentSearchResults = useRef<SearchResult[]>([]);
  const activeThreadId = useRef<string | null>(threadId || null);
  const isWaitingForThread = useRef(false);

  useEffect(() => {
    setSelectedThreadId(threadId);
  }, [threadId]);

  const contextConfig = useMemo(() => ({
    endpoint,
    body: meetingId 
      ? { ...additionalBody, meeting_ids: [meetingId] }
      : additionalBody
  }), [meetingId, additionalBody, endpoint]);

  const processMessageQueue = async () => {
    if (isProcessing.current || messageQueue.current.length === 0) return;
    
    isProcessing.current = true;
    const message = messageQueue.current.shift()!;
    await handleMessageSend(message);
    isProcessing.current = false;
    processMessageQueue();
  };

  const handleMessageSend = async (inputMessage: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}${contextConfig.endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          query: inputMessage,
          thread_id: activeThreadId.current,
          ...contextConfig.body,
        }),
      });

      if (!response.ok) throw new Error('Chat request failed');
      await handleStreamingResponse(response);
    } catch (error) {
      console.error('Chat error:', error);
      handleError();
    }
  };

  const sendMessage = async (inputMessage: string) => {
    if (!inputMessage.trim()) return;

    if (messages.length > 0 && !activeThreadId.current) {
      console.error('Thread ID is required for follow-up messages');
      handleError();
      return;
    }

    setMessages(prev => [...prev, { role: 'user', content: inputMessage }]);
    setIsLoading(true);
    setIsStreaming(true);

    messageQueue.current.push(inputMessage);
    processMessageQueue();
  };

  const fetchAllThreads = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/threads`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setThreads(data);
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  }, [token]);

  const fetchThread = useCallback(async (id: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/thread/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setMessages(data.messages);
        const lastAssistantMessage = [...data.messages]
          .reverse()
          .find(msg => msg.role === 'assistant');
        if (lastAssistantMessage?.service_content?.search_results) {
          onSearchResults?.(lastAssistantMessage.service_content.search_results);
        }
        return id;
      }
    } catch (error) {
      console.error('Error fetching thread:', error);
    }
    return null;
  }, [token, onSearchResults]);

  const startNewConversation = useCallback(() => {
    setMessages([]);
    setSelectedThreadId(null);
    activeThreadId.current = null;
    onSearchResults?.([]);
  }, [onSearchResults]);

  const handleThreadSelect = useCallback(async (id: string) => {
    if (id === '') {
      startNewConversation();
      return;
    }
    setSelectedThreadId(id);
    activeThreadId.current = id;
    await fetchThread(id);
  }, [fetchThread, startNewConversation]);

  const handleStreamingResponse = async (response: Response) => {
    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let buffer = '';
    
    accumulatedContent.current = '';
    currentSearchResults.current = [];
    setMessages(prev => [...prev, { role: 'assistant', content: '' }]);

    try {
      while (true) {
        const { done, value } = await reader!.read();
        if (done) break;

        // Decode the chunk and add to buffer
        buffer += decoder.decode(value, { stream: true });
        
        // Split buffer into lines and process each complete line
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep the last incomplete line in buffer

        for (const line of lines) {
          if (line.trim().startsWith('data: ')) {
            try {
              // Clean the line and parse
              const jsonStr = line.replace(/^data:\s*/, '').trim();
              if (jsonStr) {
                const data: StreamingData = JSON.parse(jsonStr);
                handleStreamingData(data);
              }
            } catch (e) {
              console.warn('Failed to parse line:', line);
              continue;
            }
          }
        }
      }
      
      // Process any remaining data in buffer
      if (buffer.trim().startsWith('data: ')) {
        try {
          const jsonStr = buffer.replace(/^data:\s*/, '').trim();
          if (jsonStr) {
            const data: StreamingData = JSON.parse(jsonStr);
            handleStreamingData(data);
          }
        } catch (e) {
          console.warn('Failed to parse remaining buffer:', buffer);
        }
      }
    } catch (error) {
      console.error('Streaming error:', error);
      handleError();
    } finally {
      setIsLoading(false);
      setIsStreaming(false);
    }
  };

  const handleStreamingData = (data: StreamingData) => {
    if (data.type === 'stream') {
      if (data.content) {
        accumulatedContent.current += data.content;
        updateMessage(accumulatedContent.current, currentSearchResults.current);
      }
    } else if (data.type === 'search_results' && data.results) {
      currentSearchResults.current = data.results;
      onSearchResults?.(data.results);
    } else if (data.thread_id) {
      activeThreadId.current = data.thread_id;
      setSelectedThreadId(data.thread_id);
      onThreadCreated?.(data.thread_id);
      isWaitingForThread.current = false;
    }
  };

  const updateMessage = (content: string, searchResults: SearchResult[]) => {
    setMessages(prevMessages => [
      ...prevMessages.slice(0, -1),
      {
        role: 'assistant',
        content,
        service_content: searchResults.length > 0 ? {
          output: content,
          search_results: searchResults
        } : undefined
      }
    ]);
  };

  const handleError = () => {
    setMessages(prev => [
      ...prev.slice(0, -1),
      {
        role: 'assistant',
        content: 'Sorry, an error occurred. Please try again.',
      }
    ]);
  };

  const resetMessages = useCallback(() => {
    setMessages([]);
  }, []);

  const fetchMeetingThreads = useCallback(async (meetingId: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/threads/${meetingId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setThreads(data);
        
        if (data.length > 0) {
          return data[0].thread_id;
        }
      }
      return null;
    } catch (error) {
      console.error('Error fetching meeting threads:', error);
      return null;
    }
  }, [token]);

  useEffect(() => {
    if (threadId) {
      activeThreadId.current = threadId;
      setSelectedThreadId(threadId);
    }
  }, [threadId]);

  return {
    messages,
    isLoading,
    isStreaming,
    sendMessage,
    fetchMeetingThreads,
    resetMessages,
    setMessages,
    fetchThread,
    threads,
    selectedThreadId,
    handleThreadSelect,
    startNewConversation,
    fetchAllThreads,
  };
}; 