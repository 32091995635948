import { API_BASE_URL } from '../config';

interface TokenResponse {
  user_id: string;
  user_name: string;
  image: string | null;
}

export class TokenManager {
  async submitToken(token: string): Promise<{ userId: string; userName: string; imageUrl: string | null } | null> {
    try {
      console.log('🔄 Submitting token to backend...');
      const response = await fetch(`${API_BASE_URL}/submit_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        console.error('❌ Token submission failed:', response.status);
        throw new Error(`Token submission failed: ${response.status}`);
      }

      const data: TokenResponse = await response.json();
      console.log('✅ Token submission successful');
      
      if (!data.user_id || !data.user_name) {
        console.error('❌ Invalid user data received');
        throw new Error('Invalid user data received');
      }

      return {
        userId: data.user_id,
        userName: data.user_name,
        imageUrl: data.image
      };
    } catch (error) {
      console.error('❌ Token submission error:', error);
      return null;
    }
  }

  async getUserInfo(token: string): Promise<{ userId: string; userName: string; imageUrl: string | null } | null> {
    try {
      console.log('🔄 Fetching user info...');
      const response = await fetch(`${API_BASE_URL}/user_info`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error('❌ User info fetch failed:', response.status);
        throw new Error(`User info fetch failed: ${response.status}`);
      }

      const data: TokenResponse = await response.json();
      console.log('✅ User info fetch successful');

      return {
        userId: data.user_id,
        userName: data.user_name,
        imageUrl: data.image
      };
    } catch (error) {
      console.error('❌ User info fetch error:', error);
      return null;
    }
  }
}
