import { useState, useEffect } from 'react'

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent')
    if (!consent) {
      setIsVisible(true)
    }
  }, [])

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'true')
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-zinc-900 text-white p-4 z-50">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <p className="text-sm text-gray-300">
          We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
        </p>
        <div className="flex gap-4">
          <button
            onClick={acceptCookies}
            className="bg-white text-black px-4 py-2 rounded-lg text-sm hover:bg-gray-100 transition-colors"
          >
            Accept
          </button>
          <a
            href="/privacy-policy"
            className="text-sm text-gray-300 hover:text-white transition-colors"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent