import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { Menu, X, Loader2 } from 'lucide-react';
import { TokenManager } from './utils/TokenManager';
import { API_BASE_URL } from './config';

// Components
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import LandingPage from './components/landing/LandingPage';
import AuthCallback from './components/AuthCallback';
import UserProfile from './components/UserProfile';
import FilterChatArea from './components/FilterChatArea';
import ShareAccept from './routes/ShareAccept';
import RequireAuth from './components/RequireAuth';
import DocsPage from './components/landing/docs/DocsPage';

// Types
import { Thread, UserInfo, Meeting } from './types';
import { TranscriptEntry, MeetingDetails } from './types/meetings';

// Utility function for API logging
const logApiCall = async (url: string, options: RequestInit, callName: string) => {
  console.log(`🌐 API ${callName} Request:`, {
    url,
    method: options.method || 'GET',
    headers: options.headers,
    body: options.body
  });
  
  const response = await fetch(url, options);
  if (!response.ok) {
    console.error(`❌ API ${callName} failed:`, response.status, response.statusText);
    throw new Error(`${callName} failed`);
  }
  
  const data = await response.json();
  console.log(`✅ API ${callName} Data:`, data);
  return { response, data };
};

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <Loader2 className="h-8 w-8 animate-spin text-primary" />
  </div>
);

const ErrorDisplay = ({ error }: { error: string }) => (
  <div className="flex items-center justify-center h-screen">
    <div className="text-destructive text-center">
      <h2 className="text-xl font-semibold mb-2">Error</h2>
      <p>{error}</p>
    </div>
  </div>
);

// Update UserInfo type usage
type UserInfoResponse = {
  userId: string;
  userName: string;
  imageUrl: string | null;
  image?: string;
};

function App() {
  // State management
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [threads, setThreads] = useState<Thread[]>([]);
  const [selectedThread, setSelectedThread] = useState<string | null>(null);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [isViewingMeetings, setIsViewingMeetings] = useState(true);
  const [selectedMeeting, setSelectedMeeting] = useState<string | null>(null);
  const [transcripts, setTranscripts] = useState<TranscriptEntry[]>([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [firstMeeting, setFirstMeeting] = useState<string | null>(null);
  const [lastMeeting, setLastMeeting] = useState<string | null>(null);
  const [meetingsProcessed, setMeetingsProcessed] = useState(0);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | null>(null);
  const [speakerMeetings, setSpeakerMeetings] = useState<Meeting[]>([]);

  const tokenManager = new TokenManager();
  const indexingStartedRef = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Create a ref for the handler to ensure it persists
  const loginHandlerRef = useRef<((event: CustomEvent) => void) | null>(null);

  // Initialize app
  useEffect(() => {
    const initializeApp = async () => {
      console.log('🚀 Initializing app...');
      try {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (!storedUserInfo) {
          setIsLoading(false);
          return;
        }

        const parsedUserInfo = JSON.parse(storedUserInfo);
        setUserInfo(parsedUserInfo);

        try {
          await fetchMeetings();
          await fetchThreads();
        } catch (error) {
          console.error('❌ Initial data fetch error:', error);
          // Continue execution even if some calls fail
        }


      } catch (error) {
        console.error('❌ Initialization error:', error);
        setError('Failed to initialize application');
      } finally {
        setIsLoading(false);
      }
    };

    initializeApp();
  }, []);

  // API Methods
  const fetchThreads = async (tokenParam?: string) => {
    const token = tokenParam || userInfo?.token;
    if (!token) return;
    
    try {
      const { data } = await logApiCall(
        `${API_BASE_URL}/threads`,
        {
          headers: { 'Authorization': `Bearer ${token}` },
          cache: 'no-store'
        },
        'Fetch Threads'
      );
      setThreads(data);
    } catch (error) {
      console.error('❌ Thread fetch error:', error);
      setError('Failed to load threads');
    }
  };

  // Update fetchMeetings and fetchThreads signatures
  const fetchMeetings = async (token?: string) => {
    const authToken = token || userInfo?.token;
    if (!authToken) return;
    
    try {
      const response = await fetch(`${API_BASE_URL}/meetings/all?include_summary=true`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch meetings');
      }
      
      const data = await response.json();
      console.log('Meetings API response:', data);
      setMeetings(data.meetings.map((meeting: any) => ({
        ...meeting,
        meeting_summary: meeting.meeting_summary || undefined // Convert null to undefined
      })));
    } catch (error) {
      console.error('Error fetching meetings:', error);
    }
  };




  // Auth handlers
  // Update handleLogin to handle proper types
  const handleLogin = async (token: string): Promise<boolean> => {
    console.log('🔑 Starting login process');
    try {
      const userInfoResponse = await tokenManager.submitToken(token) as UserInfoResponse;
      
      if (!userInfoResponse) {
        console.error('❌ Token validation failed');
        return false;
      }

      const newUserInfo: UserInfo = {
        token,
        userId: userInfoResponse.userId,
        userName: userInfoResponse.userName,
        imageUrl: userInfoResponse.imageUrl || userInfoResponse.image || ''  // Provide default empty string
      };

      localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
      setUserInfo(newUserInfo);

      await fetchMeetings(token);
      await fetchThreads(token);

      return true;
    } catch (error) {
      console.error('❌ Login error:', error);
      setError('Login failed. Please try again.');
      return false;
    }
  };

  const handleLogout = () => {
    console.log('🚪 Logging out...');
    localStorage.removeItem('userInfo');
    setUserInfo(null);
    setThreads([]);
    setMeetings([]);
    setSelectedThread(null);
    setSelectedMeeting(null);
    indexingStartedRef.current = false;
    navigate('/');
  };

  // UI handlers
  const handleToggleMeetings = () => {
    setIsViewingMeetings(!isViewingMeetings);
  };

  const handleSelectMeeting = (meetingId: string) => {
    setSelectedMeeting(meetingId);
    setIsViewingMeetings(true);
    setIsMobileMenuOpen(false);
  };

  const handleSpeakerMeetingsSelect = (speaker: string, meetings: Meeting[]) => {
    if (!speaker) {
      // Reset speaker-related states when empty speaker is passed
      setSelectedSpeaker(null);
      setSpeakerMeetings([]);
      setIsViewingMeetings(true);
    } else {
      setSelectedSpeaker(speaker);
      setSpeakerMeetings(meetings);
    }
  };

  const handleClearSpeakers = useCallback(() => {
    setSelectedSpeaker(null);
    setSpeakerMeetings([]);
  }, []);

  // Polling effects
  useEffect(() => {
    if (userInfo) {
      const meetingsInterval = setInterval(fetchMeetings, 30000);
      return () => {
        clearInterval(meetingsInterval);
      };
    }
  }, [userInfo]);

  // Route handling effect
  useEffect(() => {
    const meetingMatch = location.pathname.match(/\/meetings\/(.+)/);
    if (meetingMatch) {
      const meetingId = meetingMatch[1];
      setSelectedMeeting(meetingId);
      setIsViewingMeetings(true);
    }
  }, [location.pathname]);

  // Add this effect near other useEffects
  useEffect(() => {
    if (userInfo?.token) {
      const initializeData = async () => {
        await Promise.all([
          fetchMeetings(userInfo.token),
          fetchThreads(userInfo.token)
        ]);
      };
      initializeData();
    }
  }, [userInfo?.token]); // Only re-run if token changes

  // Set up the handler only once when app mounts
  useEffect(() => {
    console.log('SHARE-FLOW: Initializing login handler');

    const handleLoginSuccess = async (event: CustomEvent) => {
      try {
        console.log('SHARE-FLOW: Auth callback received');
        const { token } = event.detail;
        
        // Check both storages immediately
        const sessionToken = sessionStorage.getItem('pendingShareAccept');
        const localToken = localStorage.getItem('pendingShareAccept');
        console.log('SHARE-FLOW: Found stored tokens:', { sessionToken, localToken });

        const success = await handleLogin(token);
        console.log('SHARE-FLOW: Login result:', success);

        if (success) {
          // Use either token
          const tokenToUse = sessionToken || localToken;
          
          if (tokenToUse) {
            console.log('SHARE-FLOW: Have share token, redirecting to acceptance');
            // Clean up localStorage but keep sessionStorage for the actual acceptance
            localStorage.removeItem('pendingShareAccept');
            navigate(`/share/${tokenToUse}`);
          } else {
            console.log('SHARE-FLOW: No share token, going to app');
            navigate('/app');
          }
        }
      } catch (error) {
        console.error('SHARE-FLOW: Error in login handler:', error);
      }
    };

    // Store the handler in ref and add listener
    loginHandlerRef.current = handleLoginSuccess;
    window.addEventListener('loginSuccess', loginHandlerRef.current as EventListener);
    console.log('SHARE-FLOW: Login handler initialized and attached');

    // Cleanup
    return () => {
      console.log('SHARE-FLOW: Final cleanup of login handler');
      if (loginHandlerRef.current) {
        window.removeEventListener('loginSuccess', loginHandlerRef.current as EventListener);
        loginHandlerRef.current = null;
      }
    };
  }, []); // Empty deps array - only run once on mount

  // Log when auth token changes
  useEffect(() => {
    if (userInfo?.token) {
      console.log('SHARE-FLOW: Auth token updated');
      
      // Check if we have a pending share
      const sessionToken = sessionStorage.getItem('pendingShareAccept');
      const localToken = localStorage.getItem('pendingShareAccept');
      
      if (sessionToken || localToken) {
        console.log('SHARE-FLOW: Found pending share after auth update');
        const tokenToUse = sessionToken || localToken;
        navigate(`/share/${tokenToUse}`);
      }
    }
  }, [userInfo?.token, navigate]);

  // Render methods
  const renderAppContent = () => (
    <div className="flex h-screen">
      <div className={`fixed inset-y-0 left-0 z-50 w-64 transform transition-transform duration-300 md:relative md:translate-x-0 ${
        isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
      }`}>
        <Sidebar
          threads={threads}
          selectedThread={selectedThread}
          meetings={meetings}
          isViewingMeetings={isViewingMeetings}
          onToggleView={handleToggleMeetings}
          onSelectMeeting={handleSelectMeeting}
          onSelectThread={(threadId) => {
            setSelectedThread(threadId);
            setIsMobileMenuOpen(false);
          }}
          onNewChat={() => {
            setSelectedThread(null);
            setIsMobileMenuOpen(false);
          }}
          onLogout={handleLogout}
          userInfo={{...userInfo!, imageUrl: userInfo!.imageUrl || ''}}
          fetchThreads={fetchThreads}
          onSpeakerMeetingsSelect={handleSpeakerMeetingsSelect}
        />
      </div>

      <main className="flex-1">
        <Routes>
          <Route path="/" element={
            <FilterChatArea
              speaker={selectedSpeaker || undefined}
              token={userInfo?.token || ''}
              onChatComplete={fetchThreads}
              meetings={selectedSpeaker ? speakerMeetings : meetings}
              onMeetingSelect={handleSelectMeeting}
              onClearSpeakers={handleClearSpeakers}
            />
          } />
          <Route path="meetings/:meetingId" element={
            <FilterChatArea
              speaker={selectedSpeaker || undefined}
              token={userInfo?.token || ''}
              onChatComplete={fetchThreads}
              meetings={selectedSpeaker ? speakerMeetings : meetings}
              onMeetingSelect={handleSelectMeeting}
              onClearSpeakers={handleClearSpeakers}
            />
          } />
        </Routes>
      </main>
    </div>
  );

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={
        <Login 
          onLogin={handleLogin} 
          redirectPath={location.pathname === '/login' ? undefined : location.pathname}
          error={error} 
        />
      } />
      <Route path="/auth/callback" element={<AuthCallback onLogin={handleLogin} />} />
      <Route path="/privacy-policy" element={<DocsPage docType="privacy" />} />
      <Route path="/terms-of-use" element={<DocsPage docType="terms" />} />
      <Route path="/share/:shareToken" element={
        isLoading ? (
          <LoadingSpinner />
        ) : !userInfo ? (
          <Login 
            onLogin={handleLogin} 
            redirectPath={location.pathname}
            error={error} 
          />
        ) : (
          <ShareAccept token={userInfo.token} />
        )
      } />
      <Route path="/app/*" element={
        isLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorDisplay error={error} />
        ) : !userInfo ? (
          <Navigate to="/login" replace state={{ from: location.pathname }} />
        ) : (
          <div className="flex h-screen">
            <div className={`fixed inset-y-0 left-0 z-50 w-64 transform transition-transform duration-300 md:relative md:translate-x-0 ${
              isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
            }`}>
              <Sidebar
                threads={threads}
                selectedThread={selectedThread}
                meetings={meetings}
                isViewingMeetings={isViewingMeetings}
                onToggleView={handleToggleMeetings}
                onSelectMeeting={handleSelectMeeting}
                onSelectThread={(threadId) => {
                  setSelectedThread(threadId);
                  setIsMobileMenuOpen(false);
                }}
                onNewChat={() => {
                  setSelectedThread(null);
                  setIsMobileMenuOpen(false);
                }}
                onLogout={handleLogout}
                userInfo={{...userInfo!, imageUrl: userInfo!.imageUrl || ''}}
                fetchThreads={fetchThreads}
                onSpeakerMeetingsSelect={handleSpeakerMeetingsSelect}
              />
            </div>
            
            <main className="flex-1">
              <Routes>
                <Route path="/" element={
                  <FilterChatArea
                    speaker={selectedSpeaker || undefined}
                    token={userInfo?.token || ''}
                    onChatComplete={fetchThreads}
                    meetings={selectedSpeaker ? speakerMeetings : meetings}
                    onMeetingSelect={handleSelectMeeting}
                    onClearSpeakers={handleClearSpeakers}
                  />
                } />
                <Route path="meetings/:meetingId" element={
                  <FilterChatArea
                    speaker={selectedSpeaker || undefined}
                    token={userInfo?.token || ''}
                    onChatComplete={fetchThreads}
                    meetings={selectedSpeaker ? speakerMeetings : meetings}
                    onMeetingSelect={handleSelectMeeting}
                    onClearSpeakers={handleClearSpeakers}
                  />
                } />
              </Routes>
            </main>
          </div>
        )
      } />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
