import { motion } from 'framer-motion';

const features = [
  {
    title: "Grow Collective Intelligence Without Extra Work",
    description: "Simply by conducting your meetings as usual, you'll organically build a centralized knowledge base accessible to everyone on your team."
  },
  {
    title: "Real-Time Support During Your Meetings",
    description: "Click on highlighted keywords during meetings to immediately access relevant information, helping you make informed decisions on the spot."
  },
  {
    title: "Generate Comprehensive Reports Quickly",
    description: "Create detailed reports on organizational activities with just a few clicks, keeping you informed and strategic."
  }
];

const ValuePropositions = () => {
  return (
    <section className="py-24">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-zinc-800/30 to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity" />
              <div className="relative space-y-4 p-8">
                <h3 className="text-2xl font-semibold leading-tight min-h-[3em]">
                  {feature.title}
                </h3>
                <p className="text-gray-400 leading-relaxed">
                  {feature.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ValuePropositions;