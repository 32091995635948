export const colors = {
  sidebar: {
    input: {
      bg: "bg-gray-700",
      placeholder: "placeholder-gray-400",
      focus: "focus:ring-gray-600",
    },
    text: {
      primary: "text-gray-100",
    },
    button: {
      default: "bg-gray-700 text-gray-400 hover:text-white hover:bg-gray-600",
      active: "bg-gray-600 text-white",
    },
    scrollbar: {
      thumb: "bg-gray-600",
      track: "bg-gray-800",
    }
  }
}; 