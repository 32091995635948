import { useState, useEffect } from 'react';
import { Menu, X, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-black/80 backdrop-blur-lg' : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo and Name */}
          <Link to="/" className="flex items-center space-x-3">
            <div className="bg-black p-2 rounded-lg">
              <img 
                src="/assets/logodark.svg" 
                alt="Vexa Logo" 
                className="h-10 w-auto"
              />
            </div>
            <span className="text-2xl font-bold text-white">Vexa</span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-6">
            {/* Get Vexa Button - New prominent button */}


            {/* Product Hunt Button */}
            <a 
              href="https://www.producthunt.com/posts/vexa?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-vexa" 
              target="_blank" 
              rel="noopener noreferrer"
              className="hover:opacity-90 transition-opacity flex items-center"
            >
              <img 
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=486278&theme=dark" 
                alt="VEXA on Product Hunt" 
                className="h-[34px] w-auto"
              />
            </a>

            <a 
              href="https://chromewebstore.google.com/detail/vexa-real-time-ai-meeting/ihibgadfkbefnclpbhdlpahfiejhfibl"
              target="_blank"
              rel="noopener noreferrer"
              className="group bg-white text-black hover:bg-gray-200 px-6 py-2.5 rounded-lg transition-all flex items-center gap-2 font-medium"
            >
              Get Vexa
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </a>

            <Link
              to="/app"
              className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg backdrop-blur-sm transition-all"
            >
              User Login
            </Link>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2 text-gray-400 hover:text-white"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;