import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Header';

const DocsPage = () => {
  const [content, setContent] = useState('');
  const location = useLocation();
  
  useEffect(() => {
    const fetchDoc = async () => {
      try {
        // Get document type from the path
        const docType = location.pathname === '/privacy-policy' ? 'privacy' : 'terms';
        const response = await fetch(`/assets/${docType}.txt`);
        if (!response.ok) throw new Error('Failed to load document');
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error('Error loading document:', error);
        setContent('Error loading document. Please try again later.');
      }
    };

    fetchDoc();
  }, [location]);

  return (
    <div className="min-h-screen bg-black text-white">
      <Header />
      <div className="max-w-4xl mx-auto px-4 py-20">
        <h1 className="text-3xl font-bold mb-8">
          {location.pathname === '/privacy-policy' ? 'Privacy Policy' : 'Terms of Use'}
        </h1>
        <div className="prose prose-invert max-w-none">
          <pre className="whitespace-pre-wrap font-sans leading-relaxed">
            {content}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default DocsPage;