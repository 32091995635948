import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Search, CheckSquare, Square, Users, User } from 'lucide-react';
import { Speaker, Meeting } from '../types';
import { API_BASE_URL } from '../config';
import { format, isToday, isThisWeek } from 'date-fns';
import { cn } from '../theme/utils';
import { colors } from '../theme/colors';

interface SpeakersListProps {
  meetings: Array<{
    speakers: string[];
    meeting_id: string;
    timestamp: string;
  }>;
  onSpeakersChange: (selectedSpeakers: string[]) => void;
  token: string;
  onSpeakerMeetingsSelect: (speaker: string, meetings: Meeting[]) => void;
  onSelectionChange?: () => void;
}

const formatLastSeen = (dateStr?: string) => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  
  if (isToday(date)) {
    return format(date, 'HH:mm');
  } else if (isThisWeek(date)) {
    return format(date, 'EEE');
  }
  return format(date, 'dd MMM');
};

const SpeakersList: React.FC<SpeakersListProps> = ({ meetings, onSpeakersChange, token, onSpeakerMeetingsSelect, onSelectionChange }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [speakersData, setSpeakersData] = useState<Speaker[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSpeakers, setSelectedSpeakers] = useState<Set<string>>(new Set());
  const [isMultiSelect, setIsMultiSelect] = useState(false);

  // Extract unique speakers once when meetings change
  const allSpeakers = useMemo(() => {
    const speakers = new Set<string>();
    meetings.forEach(meeting => {
      meeting.speakers?.forEach(speaker => speakers.add(speaker));
    });
    return Array.from(speakers).sort();
  }, [meetings]);

  // Fetch speakers data
  useEffect(() => {
    const fetchSpeakers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/speakers`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setSpeakersData(data.speakers);
      } catch (error) {
        console.error('Error fetching speakers:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSpeakers();
  }, [token]);

  const handleCheckboxClick = useCallback(async (speakerName: string, event: React.MouseEvent) => {
    event.stopPropagation();
    onSelectionChange?.();
    
    setSelectedSpeakers(prev => {
      const newSelected = new Set(prev);
      if (isMultiSelect) {
        if (newSelected.has(speakerName)) {
          newSelected.delete(speakerName);
        } else {
          newSelected.add(speakerName);
        }
      } else {
        newSelected.clear();
        newSelected.add(speakerName);
      }
      return newSelected;
    });
  }, [isMultiSelect, onSelectionChange]);

  // Effect to notify parent of speaker changes
  useEffect(() => {
    if (selectedSpeakers.size === 0) {
      onSpeakersChange([]);
      onSpeakerMeetingsSelect?.('', []);
      return;
    }

    const selectedArray = Array.from(selectedSpeakers);
    onSpeakersChange(selectedArray);
    
    // Debounce the API call
    const timeoutId = setTimeout(() => {
      fetchSpeakerMeetings(selectedArray);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [selectedSpeakers]);

  const fetchSpeakerMeetings = useCallback(async (speakers: string[]) => {
    try {
      const response = await fetch(`${API_BASE_URL}/meetings/by-speakers`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ speakers })
      });
      const data = await response.json();
      onSpeakerMeetingsSelect?.(speakers, data.meetings);
    } catch (error) {
      console.error('Error fetching speaker meetings:', error);
    }
  }, [token, onSpeakerMeetingsSelect]);

  const handleSpeakerNameClick = (speakerName: string) => {
    handleCheckboxClick(speakerName, { stopPropagation: () => {} } as React.MouseEvent);
  };

  const handleModeToggle = () => {
    setIsMultiSelect(!isMultiSelect);
    // Clear selections when switching to single select
    if (!isMultiSelect) {
      setSelectedSpeakers(new Set());
      onSpeakersChange([]);
      onSpeakerMeetingsSelect?.('', []);
    }
  };

  const handleResetState = () => {
    onSelectionChange?.();
    setSelectedSpeakers(new Set());
    setSearchQuery('');
    onSpeakersChange([]);
    onSpeakerMeetingsSelect?.('', []);
  };

  const filteredSpeakers = speakersData.filter(speaker =>
    speaker.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderSpeakerItem = (speaker: Speaker, isSelected: boolean) => (
    <div
      key={speaker.name}
      className={`flex items-start justify-between py-1 px-2 rounded-md cursor-pointer hover:bg-gray-700 ${
        isSelected ? 'bg-gray-700' : ''
      }`}
      onClick={() => handleSpeakerNameClick(speaker.name)}
    >
      <div className="flex items-center gap-2 min-w-0 flex-1 mr-2">
        {isMultiSelect && (
          <div onClick={(e) => handleCheckboxClick(speaker.name, e)} className="mt-0.5 flex-shrink-0">
            {isSelected ? (
              <CheckSquare className="h-3.5 w-3.5 text-white" />
            ) : (
              <Square className="h-3.5 w-3.5 text-gray-400" />
            )}
          </div>
        )}
        <div className="flex flex-col min-w-0">
          <span className="text-sm truncate leading-tight">{speaker.name}</span>
          {speaker.last_seen && (
            <span className="text-[10px] text-gray-400 leading-tight">
              {formatLastSeen(speaker.last_seen)}
            </span>
          )}
        </div>
      </div>
      {speaker.meeting_count && (
        <span className="text-xs bg-gray-700 text-gray-300 rounded-full px-1.5 min-w-[20px] text-center mt-0.5 flex-shrink-0">
          {speaker.meeting_count}
        </span>
      )}
    </div>
  );

  const selectedSpeakersData = speakersData.filter(speaker => 
    selectedSpeakers.has(speaker.name)
  );

  const unselectedSpeakersData = speakersData.filter(speaker => 
    !selectedSpeakers.has(speaker.name) &&
    speaker.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex flex-col h-full">
      {/* Search and clear filters */}
      <div className="flex-none bg-gray-800 z-20 pb-2">
        <div className="px-4">
          <div className="flex gap-2 mb-2">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search speakers..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={cn(
                  "w-full pl-10 pr-4 py-1.5 rounded-md",
                  colors.sidebar.input.bg,
                  colors.sidebar.text.primary,
                  colors.sidebar.input.placeholder,
                  colors.sidebar.input.focus,
                  "focus:outline-none focus:ring-1"
                )}
              />
            </div>
            <button
              onClick={handleModeToggle}
              className={cn(
                "p-1.5 rounded-md transition-colors",
                isMultiSelect ? colors.sidebar.button.active : colors.sidebar.button.default
              )}
              title={isMultiSelect ? "Single-select mode" : "Multi-select mode"}
            >
              {isMultiSelect ? (
                <User className="h-4 w-4" />
              ) : (
                <Users className="h-4 w-4" />
              )}
            </button>
          </div>
          {selectedSpeakers.size > 0 && (
            <button
              onClick={handleResetState}
              className="w-full mt-2 text-xs text-gray-400 hover:text-white px-2 py-1.5 rounded-md bg-gray-700/50 hover:bg-gray-700 transition-colors flex items-center justify-center gap-1"
            >
              <span>Clear filters</span>
              <span className="text-xs text-gray-500">({selectedSpeakers.size})</span>
            </button>
          )}
        </div>
      </div>

      {/* Speakers List */}
      <div className={cn(
        "flex-1 overflow-y-auto relative",
        `[&::-webkit-scrollbar]:w-2`,
        `[&::-webkit-scrollbar-thumb]:${colors.sidebar.scrollbar.thumb}`,
        `[&::-webkit-scrollbar-track]:${colors.sidebar.scrollbar.track}`,
        `[&::-webkit-scrollbar]:${colors.sidebar.scrollbar.track}`
      )}>
        {isMultiSelect && selectedSpeakersData.length > 0 && (
          <div className="sticky top-0 bg-gray-800 z-10 border-b border-gray-700">
            <div className="px-4 py-1">
              <div className="text-xs text-gray-400 uppercase font-medium">Selected ({selectedSpeakersData.length})</div>
              <div className="space-y-0.5">
                {selectedSpeakersData.map(speaker => 
                  renderSpeakerItem(speaker, true)
                )}
              </div>
            </div>
          </div>
        )}

        <div className="px-4 pt-2">
          <div className="text-xs text-gray-400 uppercase font-medium">All Speakers</div>
          <div className="space-y-0.5 mt-1">
            {isLoading ? (
              <div className="text-sm text-gray-400">Loading...</div>
            ) : (
              isMultiSelect ? (
                unselectedSpeakersData.map(speaker => 
                  renderSpeakerItem(speaker, false)
                )
              ) : (
                filteredSpeakers.map(speaker => 
                  renderSpeakerItem(speaker, selectedSpeakers.has(speaker.name))
                )
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakersList;