import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import { Loader2 } from 'lucide-react';

const ShareAccept: React.FC<{ token: string }> = ({ token: authToken }) => {
  const { shareToken } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [error, setError] = useState<string>('');
  const acceptAttempted = useRef(false);

  console.log('SHARE-FLOW: ShareAccept mounted with:', {
    hasAuthToken: !!authToken,
    shareToken,
    acceptAttempted: acceptAttempted.current
  });

  useEffect(() => {
    const acceptShare = async () => {
      console.log('SHARE-FLOW: ShareAccept effect running with:', {
        hasAuthToken: !!authToken,
        shareToken,
        acceptAttempted: acceptAttempted.current
      });

      // Check both storages
      const sessionToken = sessionStorage.getItem('pendingShareAccept');
      const localToken = localStorage.getItem('pendingShareAccept');
      console.log('SHARE-FLOW: Found tokens in storage:', { sessionToken, localToken });

      if (acceptAttempted.current) {
        console.log('SHARE-FLOW: Share already attempted');
        return;
      }

      if (!authToken) {
        console.log('SHARE-FLOW: No auth token, storing and redirecting');
        if (shareToken) {
          // Store in both for redundancy
          sessionStorage.setItem('pendingShareAccept', shareToken);
          localStorage.setItem('pendingShareAccept', shareToken);
          console.log('SHARE-FLOW: Stored token in both storages:', shareToken);
        }
        navigate('/login');
        return;
      }

      // Use available token in order: URL > session > local
      const tokenToUse = shareToken || sessionToken || localToken;
      console.log('SHARE-FLOW: Selected token for acceptance:', tokenToUse);

      if (!tokenToUse) {
        console.log('SHARE-FLOW: No token available');
        setError('No share token found');
        setStatus('error');
        return;
      }

      try {
        acceptAttempted.current = true;
        console.log('SHARE-FLOW: Starting share acceptance with token:', tokenToUse);

        const response = await fetch(`${API_BASE_URL}/share-links/accept`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            token: tokenToUse,
            accepting_email: null
          })
        });

        console.log('SHARE-FLOW: API response status:', response.status);

        if (!response.ok) {
          const errorData = await response.json();
          console.error('SHARE-FLOW: API error:', errorData);
          throw new Error(errorData.detail || 'Failed to accept share link');
        }

        const data = await response.json();
        console.log('SHARE-FLOW: API success:', data);

        // Clean up both storages
        sessionStorage.removeItem('pendingShareAccept');
        localStorage.removeItem('pendingShareAccept');
        console.log('SHARE-FLOW: Cleaned up both storages');

        setStatus('success');
        setTimeout(() => {
          console.log('SHARE-FLOW: Redirecting to app after success');
          navigate('/app');
        }, 2000);
      } catch (err: any) {
        console.error('SHARE-FLOW: Error during acceptance:', err);
        setError(err.message);
        setStatus('error');
      }
    };

    acceptShare();
  }, [authToken, navigate, shareToken]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="max-w-md w-full mx-auto p-6">
        <div className="bg-white rounded-lg shadow-sm border p-6 text-center">
          {status === 'loading' && (
            <>
              <Loader2 className="w-8 h-8 animate-spin mx-auto text-primary" />
              <h2 className="mt-4 text-lg font-medium">
                Accepting shared meeting...
              </h2>
            </>
          )}
          
          {status === 'success' && (
            <>
              <div className="w-8 h-8 bg-green-100 text-green-600 rounded-full flex items-center justify-center mx-auto">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="mt-4 text-lg font-medium text-green-600">
                Meeting access granted!
              </h2>
              <p className="mt-2 text-sm text-gray-500">
                Redirecting to app...
              </p>
            </>
          )}

          {status === 'error' && (
            <>
              <div className="w-8 h-8 bg-red-100 text-red-600 rounded-full flex items-center justify-center mx-auto">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              <h2 className="mt-4 text-lg font-medium text-red-600">
                Failed to accept share
              </h2>
              <p className="mt-2 text-sm text-gray-500">{error}</p>
              <button 
                onClick={() => navigate('/app')}
                className="mt-4 text-sm text-primary hover:underline"
              >
                Return to app
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareAccept; 