import React, { useEffect } from 'react';
import { GoogleAuth } from '../utils/GoogleAuth';
import { useNavigate, useLocation } from 'react-router-dom';

interface LoginProps {
  onLogin: (token: string) => Promise<boolean>;
  redirectPath?: string;
  error?: string;
}

const Login: React.FC<LoginProps> = ({ onLogin, redirectPath, error }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Store token from URL if present
  useEffect(() => {
    console.log('SHARE-FLOW: Login component mounted');
    console.log('SHARE-FLOW: Current path:', location.pathname);
    
    const shareTokenMatch = location.pathname.match(/\/share\/([^/]+)/);
    if (shareTokenMatch) {
      const shareToken = shareTokenMatch[1];
      console.log('SHARE-FLOW: Found token in URL:', shareToken);
      
      // Store in both storages
      sessionStorage.setItem('pendingShareAccept', shareToken);
      localStorage.setItem('pendingShareAccept', shareToken);
      
      // Verify storage
      console.log('SHARE-FLOW: Stored in session:', sessionStorage.getItem('pendingShareAccept'));
      console.log('SHARE-FLOW: Stored in local:', localStorage.getItem('pendingShareAccept'));
    }
  }, [location.pathname]);

  const handleGoogleLogin = () => {
    // Log storage state before starting auth
    const sessionToken = sessionStorage.getItem('pendingShareAccept');
    const localToken = localStorage.getItem('pendingShareAccept');
    console.log('SHARE-FLOW: Starting auth with tokens:', { sessionToken, localToken });
    GoogleAuth.initiateLogin();
  };

  return (
    <div className="h-screen bg-black flex">
      {/* Left side - Login Form (flexible width) */}
      <div className="flex-1 flex flex-col justify-between p-12">
        {/* Back button */}
        <button 
          onClick={() => navigate('/')}
          className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors group w-fit"
        >
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            className="group-hover:-translate-x-1 transition-transform"
          >
            <path 
              d="M19 12H5M5 12L12 19M5 12L12 5" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </button>

        <div className="flex-1 flex flex-col justify-center max-w-md mx-auto w-full">
          <div className="mb-16">
            <img 
              src="/assets/logodark.svg" 
              alt="Vexa" 
              className="h-8 mb-8"
            />
            <h2 className="text-white text-4xl font-bold mb-4">Experience New Vexa Dashboard</h2>
            <p className="text-gray-400">Log In to continue</p>
          </div>

          <button
            onClick={handleGoogleLogin}
            className="w-full flex items-center justify-center gap-3 px-6 py-3 
                     bg-[#111111] hover:bg-[#1a1a1a] rounded-lg border border-[#333333]
                     text-white transition-all"
          >
            <img 
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
              alt="Google" 
              className="w-5 h-5"
            />
            <span>Sign in with Google</span>
          </button>
        </div>

        <div className="text-gray-500 text-sm text-center">
          © Vexa {new Date().getFullYear()}
        </div>
      </div>

      {/* Right side - Feature preview */}
      <div className="hidden md:block flex-1 bg-[#111111] relative">
        <div className="absolute inset-0 bg-[url('/assets/pattern.png')] opacity-50" />
        <img 
          src="/assets/login.png" 
          alt="Vexa Interface Preview" 
          className="w-full h-screen object-cover"
        />
      </div>
    </div>
  );
};

export default Login;
