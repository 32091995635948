import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleAuth } from '../utils/GoogleAuth';

interface AuthCallbackProps {
  onLogin: (token: string) => Promise<boolean>;
}

const AuthCallback: React.FC<AuthCallbackProps> = ({ onLogin }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const hasAttempted = useRef(false);

  useEffect(() => {
    const handleCallback = async () => {
      if (hasAttempted.current) return;
      hasAttempted.current = true;
      
      const code = searchParams.get('code');
      const error = searchParams.get('error');

      if (error) {
        console.error('❌ Google auth error:', error);
        setError('Authentication failed');
        setTimeout(() => navigate('/login'), 2000);
        return;
      }

      if (!code) {
        console.error('❌ No auth code received');
        setError('No authorization code received');
        setTimeout(() => navigate('/login'), 2000);
        return;
      }

      const utmParams = {
        utm_source: searchParams.get('utm_source'),
        utm_medium: searchParams.get('utm_medium'),
        utm_campaign: searchParams.get('utm_campaign'),
        utm_term: searchParams.get('utm_term'),
        utm_content: searchParams.get('utm_content')
      };

      const cleanUtmParams = Object.fromEntries(
        Object.entries(utmParams).filter(([_, v]) => v != null)
      );

      console.log('✅ Received auth code, processing with UTM params:', cleanUtmParams);
      const authData = await GoogleAuth.handleCallback(code, cleanUtmParams);
      
      if (authData && authData.token) {
        console.log('✅ Auth data received, logging in...');
        const success = await onLogin(authData.token);
        if (success) {
          console.log('✅ Login successful, redirecting to app...');
          navigate('/app');
        } else {
          console.error('❌ Login failed');
          setError('Login failed');
          setTimeout(() => navigate('/login'), 2000);
        }
      } else {
        console.error('❌ No auth data received');
        setError('Authentication failed');
        setTimeout(() => navigate('/login'), 2000);
      }
    };

    handleCallback();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      {error ? (
        <div className="text-red-500 mb-4">{error}</div>
      ) : (
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      )}
    </div>
  );
};

export default AuthCallback; 